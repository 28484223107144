@charset "UTF-8";
/* CSS Document */
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@300;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");


.bi::before {
  display: inline-block;
  content: "";
  /*background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='%23333' xmlns='http:/*www.w3.org/2000/svg'><path fill-rule='evenodd' d='M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z' clip-rule='evenodd'/></svg>");*/
  background-repeat: no-repeat;
  background-size: 1rem 1rem;
}

html,
body {
  height: 100%;
}

body {
  font-family: 'Roboto', Verdana, Arial;
  font-size: 0.875rem;
  background-color: #fff;
  /* background: linear-gradient(to bottom, #9bb8df 0%, #fff 100%); */
  /* background-color: #e4f1ff; */
  background-attachment: fixed;
  min-height: 100vh;
}

body .small-header h2 {
  font-size: calc(100vw * 0.015);
  line-height: calc(100vw * 0.015);
}

a {
  color: #f79000;
}
a:hover {
  color: #262626;
}

label {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.autocomplete-container {
  box-shadow: none !important;
}

.autocomplete-container .input-container input {
  background-color: #e5e5e5 !important;
  border: none !important;
  color: #555 !important;
  padding: .375rem .75rem !important;
  border-radius: .25rem !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
}

.modal-dialog.system.invite .selected-member {
  margin-left: auto!important;
  margin-right: auto!important;
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #e5e5e5;
  margin-top: 10px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}

.modal-dialog.system.invite .selected-member .username {
  font-weight: bold;
  font-size: 18px;
  margin-top: 5px;
}

.modal-dialog.system.invite .selected-member .profile-avatar {
  margin-left: auto!important;
  margin-right: auto!important;
}

.suggestion-username {
  flex-basis: 100%;
}

.suggestion-coins, .suggestion-xp, .suggestion-league {
  display: none !important;
}

.suggestions-container ul .item > div {
  display: flex;
  flex-direction: row;
  padding: 10px;
}

.suggestions-container ul .item .profile-avatar {
  display:block
}

.suggestions-container ul .item div a {
  font-weight: bold;
  align-items: center;
  display: flex !important;
}

.suggestions-container ul .item > div > div {
  align-items: center;
  display: flex;
}

.modal-dialog.system.invite .modal-content {
  overflow: visible !important;
}

.modal-dialog.system.invite .modal-body {
  padding-top: 2.2rem !important;
  text-align: left;
  min-height: 150px;
}

.scroll-to-top {
  width: 40px;
  height: 40px;
  display: flex;
  position: fixed;
  bottom: 15px;
  right: 15px;
  z-index: 999;
  justify-content: center;
  align-items: center;
  z-index: 1031;
  -webkit-border-radius: 80px;
  -moz-border-radius: 80px;
  border-radius: 80px;
  border: 3px solid #dbdbdb;
  background-color: #FFF;
  -webkit-box-shadow: 4px 4px 8px 0px rgba(50, 50, 50, 0.55);
  -moz-box-shadow: 4px 4px 8px 0px rgba(50, 50, 50, 0.55);
  box-shadow: 4px 4px 8px 0px rgba(50, 50, 50, 0.55);
  cursor: pointer;
  transition:all 500ms ease-out;
  opacity: 0;
  visibility: hidden;
}

.scroll-to-top i {
  color: #262626;
  font-size: 18px;
}

hr.hr-text {
  position: relative;
  border: none;
  height: 1px;
  background: #e5e5e5;
}

hr.hr-text::before {
  content: attr(data-content);
  display: inline-block;
  background: #fff;
  font-weight: bold;
  font-size: 1rem;
  color: #afafaf;
  border-radius: 30rem;
  padding: 0.2rem 0.8rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading-notification {
  position: relative;
  padding: 1rem 1.25rem;
  margin-bottom: 1rem;
  text-align: center;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.25) !important;
  border: 2px solid #fff;
}

.loading-notification.no-filter {
  -moz-border-radius-topleft: 8px !important;
  -webkit-border-top-left-radius: 8px !important;
   border-top-left-radius: 8px !important;
  -moz-border-radius-topright: 8px !important;
  -webkit-border-top-right-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.loading-notification.normal { /* 3 dots weiss */
  color: #212529;
  background-color: #fff;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  margin-bottom: 0px;
}

.loading-notification.list { /* 3 dots weiss */
  color: #212529;
  background-color: #fff;
  -moz-border-radius-bottomright: 8px;
  -webkit-border-bottom-right-radius: 8px;
  border-bottom-right-radius: 8px;
  -moz-border-radius-bottomleft: 8px;
  -webkit-border-bottom-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.loading-notification.reload { /* reload blau */
  color: #212529;
  background-color: #a0c9ff;
  border-color: #c4ddff;
  border-radius: 0px;
  margin: 0px;
}

.loading-notification.empty { /* gelb */
  color: #212529;
  background-color: #ffcd57;
  border-color: #ffe29c;
  -moz-border-radius-bottomright: 8px;
  -webkit-border-bottom-right-radius: 8px;
  border-bottom-right-radius: 8px;
  -moz-border-radius-bottomleft: 8px;
  -webkit-border-bottom-left-radius: 8px;
  border-bottom-left-radius: 8px;
  margin-bottom: 0px;
}

@keyframes dot-keyframes {
  0% {
    opacity: 0.4;
    transform: scale(1, 1);
  }
  50% {
    opacity: 1;
    transform: scale(1.2, 1.2);
  }
  100% {
    opacity: 0.4;
    transform: scale(1, 1);
  }
}
.loading-dots {
  text-align: center;
  width: 100%;
}
.loading-dots--dot {
  animation: dot-keyframes 1.5s infinite ease-in-out;
  background-color: #000;
  border-radius: 10px;
  display: inline-block;
  height: 10px;
  width: 10px;
  margin-left: 2px;
  margin-right: 2px;
}
.loading-dots--dot:nth-child(2) {
  animation-delay: 0.5s;
}
.loading-dots--dot:nth-child(3) {
  animation-delay: 1s;
}

.btn-group-sm > .btn,
.btn-sm {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}

.carousel-indicators li {
  width: 17px;
  height: 17px;
  border-radius: 100%;
  background-color: #000;
  opacity: 1;
}

.carousel-indicators .active {
  background-color: #fe5114;
  opacity: 1;
}

.highlights-slider {
  display: flex;
  margin-bottom: 30px;
  max-height: 183px;
  overflow: hidden;
  position: relative;
  background-color: #FFF;
  border-radius: 8px;
}

.highlights-slider .arrow-left,
.highlights-slider .arrow-right {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  border-radius: 60px;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  z-index: 1;
  -webkit-box-shadow: 4px 4px 8px 0px rgba(50, 50, 50, 0.55);
  -moz-box-shadow: 4px 4px 8px 0px rgba(50, 50, 50, 0.55);
  box-shadow: 4px 4px 8px 0px rgba(50, 50, 50, 0.55);
}

.highlights-slider .loading-dots {
  align-items: center;
  justify-content: center;
  display: flex;
}

.highlights-slider .arrow-left:hover i,
.highlights-slider .arrow-left:focus i,
.highlights-slider .arrow-right:hover i,
.highlights-slider .arrow-right:focus i {
  color: #ffb600;
}

.highlights-slider .arrow-left i,
.highlights-slider .arrow-right i {
  color: #262626;
}

.highlights-slider .arrow-left {
  left: 5px;
}

.highlights-slider .arrow-right {
  right: 5px;
}

.highlights-slider .arrow-left i {
  transform: translateX(-1px);
}

.highlights-slider .arrow-right i {
  transform: translateX(1px);
}

.highlights-slider .slides-container {
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  flex-direction: column;
  transition: -webkit-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
}

.highlights-slider .quick-bet {
  border-right: 1px solid #dbdbdb;
  padding: 16px;
  background-color: #FFF;
}

.highlights-slider .quick-bet:last-child {
  border-right: 0px;
}

.quick-bet {
  color: #262626;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 0px 16px 0px 16px;
  position: relative;
  overflow: hidden;
  max-height: 190px;
  width: 275px;
}

.quick-bet.goal:before {
  content: "";
  position: absolute;
  background: linear-gradient(
    90deg,
    rgba(255, 182, 0, 1) 0%,
    rgba(255, 182, 0, 1) 100%
  );
  width: 100%;
  opacity: 0;
  top: 0px;
  left: 0px;
  height: 100%;
  animation: target-fade-2 1s 1;
  -webkit-animation: target-fade-2 1s 1;
  -moz-animation: target-fade-2 2s 1;
  z-index: 0;
}

@keyframes target-fade-2 {
  0% {
    opacity: 0.7;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes target-fade-2 {
  0% {
    opacity: 0.7;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes target-fade-2 {
  0% {
    opacity: 0.7;
  }
  100% {
    opacity: 0;
  }
}

.quick-bet .headline {
  z-index: 1;
  font-size: 10px;
  line-height: 20px;
  font-weight: 800;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  padding: 0px 0px 5px 10px;
  text-align: left;
  margin-left: -10px;
  position: relative;
  overflow: hidden;
  vertical-align: middle;
}

.quick-bet .headline .status {
  color: #ffb600;
  display: flex;
  justify-content: flex-end;
  font-weight: bold;
  white-space: nowrap;
}

.quick-bet .headline .status .time {
  margin-left: 5px;
  color: #262626;
}

.quick-bet .headline .league {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 5px;
}

.quick-bet .headline .sport {
  display: flex;
  margin-right: 5px;
}

.quick-bet .headline .sport img {
  width: 18px;
  height: 18px;
}

.quick-bet .opponents {
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding: 5px 0px;
  margin-bottom: 5px;
}

.quick-bet .opponents .live-result {
  flex: 1 1 0;
}

.quick-bet .opponents .home-team,
.quick-bet .opponents .guest-team {
  text-align: left;
  display: flex;
}

.quick-bet .opponents .home-team {
  margin-bottom: 5px;
}

.quick-bet .place-bet {
  z-index: 1;
  display: flex;
}

.quick-bet .label {
  z-index: 1;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  text-align: center;
  font-size: 12px;
  color: #7f7f7f;
  padding-top: 5px;
  padding-bottom: 5px;
}

.quick-bet .place-bet .bet-button {
  background-color: #fff;
  flex: 1;
  margin-right: 3px;
  font-size: 14px;
  font-weight: bold;
  color: #262626;
  border: 1px solid #dbdbdb;
  position: relative;
  border-radius: 4px;
}

.quick-bet .place-bet .bet-button:last-child {
  margin: 0px;
}

.quick-bet .place-bet .bet-button:hover {
  background-color: #262626;
  color: #fff;
  border-color: #262626;
}

.quick-bet .place-bet .bet-button:hover span {
  color: #FFF !important;
}

.quick-bet .place-bet .bet-button:focus {
  box-shadow: none;
}

.quick-bet .place-bet .bet-button.active {
  background-color: #ffb600;
  color: #fff !important;
  border-color: #ffb600;
}

.quick-bet .place-bet .bet-button.active span {
  color: #FFF !important;
}

.quick-bet .place-bet .bet-button.inactive:before {
  content: "\f023" !important;
  font-size: 16px !important;
  line-height: 24px;
  color: #878787 !important;
  font-style: normal;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  padding-top: 3px;
}

.quick-bet .place-bet .bet-button.inactive {
  font-size: 0px;
  cursor: default;
}

.quick-bet .place-bet .bet-button.inactive:hover {
  background-color: #FFF;
  border-color: #dbdbdb;
}

.quick-bet .place-bet .bet-button.tend-up:before,
.quick-bet .place-bet .bet-button.tend-down:before {
  right: -2px;
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  position: absolute;
}

.quick-bet .place-bet .bet-button.tend-down {
  color: #ed1f1f;
}

.quick-bet .place-bet .bet-button.tend-up {
  color: #3bcc17;
}

.quick-bet .place-bet .bet-button.active.tend-up:before,
.quick-bet .place-bet .bet-button.active.tend-down:before {
  border-bottom: 6px solid #FFF;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 1px;
}

.quick-bet .place-bet .bet-button.tend-up:before {
  border-bottom: 6px solid #3bcc17;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 1px;
}

.quick-bet .place-bet .bet-button.tend-down:before {
  border-bottom: 6px solid #ed1f1f;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  bottom: 1px;
}

.quick-bet .place-bet .bet-button.home-win span,
.quick-bet .place-bet .bet-button.draw span,
.quick-bet .place-bet .bet-button.away-win span {
  color: #7f7f7f;
  text-align: left;
  float: left;
  font-weight: normal;
}

.tutorial .img-size {
  /*  padding: 0;
  margin: 0; */
  height: 619px;
  width: 498px;
  background-size: cover;
  overflow: hidden;
}
.tutorial .modal-content {
  border: none;
}
.tutorial .modal-body {
  padding: 0 !important;
  background-image: url("../images/tutorial-bg.jpg");
  background-size: cover;
  background-position: center center;
}

.tutorial .carousel-control-prev-icon {
  /*background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");*/
  width: 30px;
  height: 48px;
}
.tutorial .carousel-control-next-icon {
  /*background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");*/
  width: 30px;
  height: 48px;
}

.modal-dialog-slideout {
  min-height: 100%;
  margin: 0 0 0 auto;
  background: #fff;
}
.modal.fade .modal-dialog.modal-dialog-slideout {
  -webkit-transform: translate(100%, 0) scale(1);
  transform: translate(100%, 0) scale(1);
}
.modal.fade.show .modal-dialog.modal-dialog-slideout {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  display: flex;
  align-items: stretch;
  -webkit-box-align: stretch;
  height: 100%;
}
.modal.fade.show .modal-dialog.modal-dialog-slideout .modal-body {
  overflow-y: auto;
  overflow-x: hidden;
}
.modal-dialog-slideout .modal-content {
  border: 0;
}
.modal-dialog-slideout .modal-header,
.modal-dialog-slideout .modal-footer {
  height: 69px;
  display: block;
}
.modal-dialog-slideout .modal-header h5 {
  float: left;
}

.mobile-betslip .modal-content {
  background-color: #262626;
  border-radius: 0px;
}

.mobile-betslip .modal-header {
  height: auto;
  color: #fff;
  text-align: center;
  border: 0px;
  padding: 1rem 1rem 0 1rem;
}

.mobile-betslip .modal-header .close {
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 6rem;
  padding: 0px 15px 0px 15px;
  margin: 0;
  text-shadow: 0 1px 0 #fff;
  color: #fff;
  z-index: 1;
  line-height: 5.5rem;
}

.mobile-betslip .modal-footer {
  height: 234px;
  padding: 0px;
  background-color: #1d1d1d;
}

.mobile-betslip .modal-footer.combi {
  height: 320px;
}

.mobile-betslip .modal-footer.system {
  height: 378px;
}

.mobile-betslip .betslip-options {
  margin: 0px;
}

.mobile-betslip .betslip-delete-all {
  font-size: 17px;
}

.mobile-betslip .coins-einsatz {
  margin: 0px;
}

.mobile-betslip .betslip-custom-headline {
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 0px;
}

.mobile-betslip .betslip-outcome {
  padding: 15px;
}

.mobile-betslip .betslip-outcome li.outcome-xp {
  width: 50%;
  align-items: center;
}

.mobile-betslip .betslip-outcome li.outcome-coins {
  width: 50%;
  align-items: center;
}

.mobile-betslip .betslip-outcome li div {
  font-size: 24px;
}

.mobile-betslip .coins-einsatz a {
  color: #fff !important;
}

.mobile-betslip .coins-einsatz input {
  font-size: 24px;
}

.mobile-betslip .betslip-action {
  padding: 0px;
  margin: 15px;
}

.mobile-betslip .modal-header h2 {
  font-size: 28px;
  font-weight: 700;
}

.mobile-betslip .betslip-custom {
  margin: 0px;
  padding-left: 15px;
  padding-right: 15px;
}

.mobile-betslip .betslip-tabs {
  margin-right: -1rem;
  margin-left: -1rem;
}

.mobile-betslip .betslip-tab {
  color: #fff !important;
}

.mobile-betslip .betslip-tab.active {
  background: #0e0e0e;
  color: #ffb600 !important;
  text-decoration: none;
  text-shadow: 0px 0px 8px rgba(255, 182, 0, 0.75);
}

.mobile-betslip .betslip-tab:hover {
  background: #313131;
  color: #fff;
  text-decoration: none;
}

.mobile-betslip .betslip-tab.active:hover {
  background-color: #0e0e0e;
}

.mobile-betslip .betslip-tab.disabled,
.mobile-betslip .betslip-tab.disabled:hover {
  color: #363636 !important;
  text-shadow: none;
}

.mobile-betslip .betslip-entries {
  overflow: none;
  max-height: none;
}

.mobile-betslip .modal-header .betslip-countdown {
  color: #999;
  margin-bottom: 15px;
}

.mobile-betslip .modal-body {
  padding: 0px !important;
  color: #fff;
}

.animate__delay-2500ms {
  animation-delay: 2.5s;
}

.animate__delay-1500ms {
  animation-delay: 1.5s;
}

.animate__delay-500ms {
  animation-delay: 0.5s;
}

.card {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}

.card.ranking-notice {
  margin-right: 20px;
  text-align: center;
  border: 0px;
  margin-bottom: 15px;
}

.alert {
  margin-bottom: 0;
}

.chat-box {
  background-color: #f8f9fa;
  border-radius: 8px;
  margin-bottom: 30px;
  overflow: hidden;
}

.chat-box .entry-list {
  overflow-y: auto;
  max-height: 400px;
  padding: 25px;
}

.chat-box .header {
  background: #ececec !important;
  padding: 9px 12px 11px;
  color: #262626;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.10) !important;
}

.chat-box .footer {
  background: none !important;
  padding: 12px;
  color: #262626;
  box-shadow: 0.5rem 0 1rem rgba(0, 0, 0, 0.15) !important;
}

.chat-box .seperator {
  text-align: center;
  margin-bottom: 30px;
}

.chat-box .seperator .inner {
  background-color: #d7d7d7;
  color: #333;
  display: inline-block;
  font-weight: bold;
  font-size: 80%;
  margin: 0 auto;
  padding: 2px 15px;
  text-align: center;
  border-radius: 20px;
  max-width: 50%;
}

.chat-box .entry {
  margin-bottom: 30px;
}

.chat-box .entry {
  -ms-flex-pack: start!important;
  justify-content: flex-start!important;
}

.chat-box .entry .username {
  font-weight: bold;
  color: #262626;
  font-size: 13px;
  margin-bottom: 3px;
}

.chat-box .entry .username .label {
  color: #FFF;
  background-color: green;
  border-radius: 20px;
  padding: 2px 7px;
}

.chat-box .entry.own {
  -ms-flex-pack: end!important;
  justify-content: flex-end!important;
}

.chat-box .entry .avatar {
  height: 40px;
  width: 40px;
  -ms-flex-order: 1;
  order: 1;
}

.chat-box .entry .avatar img {
  height: 40px;
  width: 40px;
  border: 1.5px solid #f5f6fa;
  border-radius: 50%!important;
}

.chat-box .entry .message {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
  border-radius: 6px;
  background-color: #FFF;
  padding: 10px 13px;
  width: 100%;
  position: relative;
  -ms-flex-order: 2;
  order: 2;
  -webkit-box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.20);
  -moz-box-shadow:    1px 1px 0px 0px rgba(0, 0, 0, 0.20);
  box-shadow:         1px 1px 0px 0px rgba(0, 0, 0, 0.20);
}

.chat-box .entry.own .message {
  margin-left: 0px;
  margin-right: 10px;
}

.chat-box .entry.own .avatar {
  -ms-flex-order: 2;
  order: 2;
}

.chat-box .entry.own .message {
  background-color: #d9fdd3;
  -ms-flex-order: 1;
  order: 1;
}

.chat-box .entry .time {
  position: absolute;
  left: 0;
  bottom: -15px;
  color: rgba(0,0,0,0.6);
  font-size: 10px;
}

.chat-box .entry.own .time {
  right: 0;
  left: auto;
}

.coin-label {
  text-transform: uppercase;
  font-size: 20px;
  font-size: 18px;
  font-family: "Oswald", sans-serif;
  font-weight: 550;
  color: #a7a7a7;
}

.fa-google {
  background: conic-gradient(
      from -45deg,
      #ea4335 110deg,
      #4285f4 90deg 180deg,
      #34a853 180deg 270deg,
      #fbbc05 270deg
    )
    73% 55%/150% 150% no-repeat;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
}

.fa-facebook {
  color: #2e89ff;
}

.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-x:focus,
.ps .ps__rail-x:hover,
.ps .ps__rail-y.ps--clicking,
.ps .ps__rail-y:focus,
.ps .ps__rail-y:hover {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.tooltip {
  display: inline;
  position: relative;
}

.content-area.arena .list-box.my-bets {
  overflow: auto;
  max-height: 280px;
  margin-bottom: 15px;
}

.content-area.arena .list-box.my-bets .entry .cell.bet .medium-icon {
  display: none !important;
}

.content-area {
  height: 100% !important;
}


.content-area.tippspiel .inner-sidebar {
  overflow-x: visible;
  overflow-y: visible ;
}

.arena-content-1,
.arena-content-2 {
  height: 100%;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  overflow: hidden;
}

.arena-content-1 {
  color: #fff;
}

.arena-content-1 h2 {
  font-size: 42px !important;
  margin-bottom: 4px;
}

.little-nav button {
  border-radius: 0px !important;
  background-color: #262626;
  color: #fff;
  padding: 0.5rem 0.75rem;
}

.little-nav button:hover {
  color: #fff;
  background-color: #2e2e2e;
}

.little-nav button:focus {
  box-shadow: none;
}

.little-nav button.active {
  border-bottom: 3px solid #ffb600;
  background-color: #1a1a1a;
}

.arena-block.advertisement {
  min-height: 200px;
  text-align: center;
}

.top-winner .headline {
  margin-bottom: 0px;
  font-size: calc(100vw * 0.023);
  line-height: calc(100vw * 0.026);
  color: #fff;
  font-weight: 700;
  text-shadow: 0px 0px 15px rgba(150, 150, 150, 1);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased !important;
  -moz-font-smoothing: antialiased !important;
  text-rendering: optimizelegibility !important;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  text-align: center;
  padding: 20px 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.06);
  background-color: #363636;
}

.top-winner .winner-list {
  max-height: 460px !important;
  min-height: 460px;
  overflow-y: auto;
}

.winner-list .winner-list-entry .content {
  display: flex;
  justify-content: space-between;
  padding: 5px 0 5px 16px;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.06);
}

.winner-list .winner-list-entry .content {
  background-color: #262626;
}

.winner-list .winner-list-entry:hover .content {
  background-color: #0e0e0e;
  cursor: pointer;
}

.winner-list .winner-list-entry .details {
  display: none;
}

.winner-list .winner-list-entry.open .details {
  display: block;
}

.winner-list .winner-list-entry .details ul {
  background-color: #363636;
  color: #999;
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}

.winner-list .winner-list-entry .details li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.06);
  padding: 8px 16px;
}

.winner-list .winner-list-entry .details li.quote {
  display: flex;
  font-size: 16px;
}

.winner-list .winner-list-entry .details li.quote .title {
  color: #fff;
  width: 50%;
  text-align: left;
}

.winner-list .winner-list-entry .details li.quote .value {
  color: #fff;
  font-weight: bold;
  margin-top: 0px;
  width: 50%;
  text-align: right;
}

.winner-list .winner-list-entry .details li:last-child {
  border: 0px;
}

.winner-list .winner-list-entry .details li .title {
  color: #fff;
  display: block;
}

.winner-list .winner-list-entry .details li .teams {
  display: block;
  color: #999;
}

.winner-list .winner-list-header {
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
  align-items: center;
  border-top: 1px solid rgba(255, 255, 255, 0.06);
  border-bottom: 1px solid rgba(255, 255, 255, 0.06);
  background-color: #363636;
}

.winner-list .winner-list-header .winner-list-rank {
  flex: 0 40px;
  color: #999;
}

.winner-list .winner-list-header .winner-list-win {
  font-weight: bold;
  color: #999;
}

.winner-list .winner-list-header .winner-list-win {
  font-weight: bold;
  color: #999;
}

.winner-list .winner-list-header .winner-list-win,
.winner-list .winner-list-header .winner-list-inset {
  flex: 1;
}

.winner-list .winner-list-header .winner-list-inset {
  text-align: right;
  color: #999;
}

.winner-list .winner-list-entry .winner-list-rank {
  flex: 0 40px;
  color: #999;
  font-size: 16px;
}

.winner-list .winner-list-entry .winner-list-win {
  font-weight: bold;
}

.winner-list .winner-list-entry .winner-list-win,
.winner-list .winner-list-entry .winner-list-inset {
  flex: 1;
  font-size: 16px;
  color: #fff;
}

.winner-list .winner-list-entry .winner-list-inset {
  text-align: right;
  color: #999;
}

.winner-list .winner-list-entry .winner-list-arrow {
  width: 40px;
  text-align: center;
}

.winner-list .winner-list-entry .winner-list-arrow img {
  width: 30px;
}

.winner-list .winner-list-entry.open .winner-list-arrow img {
  transform: rotate(180deg);
}

.arena-block {
  background: #1a1a1a;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 15px;
}

.arena-block.highlights {
  justify-content: center;
  align-items: center;
  text-align: center;
}

.arena-block.highlights .carousel-inner {
  overflow: visible;
}

.arena-block .block-header-text {
  position: relative;
  z-index: 1;
}

.arena-block .block-header-text h2 {
  font-size: calc(100vw * 0.023);
  line-height: calc(100vw * 0.026);
  margin: 0 0 6px;
  color: #fff;
  font-weight: 700;
  text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.4), 0px 8px 13px rgba(0, 0, 0, 0.1),
    0px 18px 23px rgba(0, 0, 0, 0.1);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased !important;
  -moz-font-smoothing: antialiased !important;
  text-rendering: optimizelegibility !important;
  letter-spacing: 0.03em;
}

.arena-block.daily-missions {
  background-color: #1a1a1a;
}

.arena-block.daily-missions .block-header-text h2 {
  line-height: 45px;
}

.arena-block .block-header {
  color: #fff;
  min-height: 250px;
  position: relative;
  text-align: center;
  padding: 190px 0 18px;
  box-sizing: border-box;
  position: relative;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.small-header {
  min-height: 150px !important;
  padding: 110px 0 0 !important;
  background-size: 100% !important;
}

.arena-block .block-header-gradient {
  width: 100%;
  height: 135px;
  position: absolute;
  bottom: 0;
  background: linear-gradient(
    to bottom,
    rgba(38, 38, 38, 0) 0%,
    rgba(38, 38, 38, 1) 60%,
    rgba(38, 38, 38, 1) 80%
  );
}

.arena-block.daily-missions .block-header-gradient {
  background: linear-gradient(
    to bottom,
    rgba(38, 38, 38, 0) 0%,
    rgba(26, 26, 26, 1) 60%,
    rgba(26, 26, 26, 1) 80%
  );
}

.arena-block .small-header .block-header-gradient {
  background: linear-gradient(
    to bottom,
    rgba(26, 26, 26, 0) 30%,
    rgba(26, 26, 26, 1) 90%,
    rgba(26, 26, 26, 1) 100%
  );
  height: 150px;
}

.block-countdown {
  font-weight: 300;
  font-size: calc(100vw * 0.012);
  letter-spacing: 3px;
}

.block-countdown span {
  color: #ffb600;
}

.arena-block .block-content {
  color: #fff;
}

.arena-block.highlights .block-content {
  padding: 0px 30px;
}

.mission-list {
  list-style: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #1a1a1a;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
}

.mission-list .mission-entry.done {
  background: rgb(30, 50, 32);
  background: linear-gradient(
    90deg,
    rgba(30, 50, 32, 1) 0%,
    rgba(26, 26, 26, 1) 46%,
    rgba(26, 26, 26, 1) 100%
  );
}

.mission-list .headline {
  font-size: 18px;
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  color: #fbb603;
}

.mission-list .mission-entry .mission-title {
  color: #fff;
  font-weight: bold;
  font-size: 1.1rem;
}

.mission-list .mission-entry .mission-body {
  color: #7f7f7f;
  margin-bottom: 8px;
}

.mission-list .mission-entry {
  display: flex;
  flex-direction: row;
  padding: 13px 15px 18px 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  background-color: #1a1a1a;
}

.mission-list .mission-entry .mission-content {
  margin-left: 10px;
}

.mission-list .mission-entry .mission-reward {
  font-size: 1rem;
  color: #fbb603;
  text-transform: uppercase;
  font-weight: bold;
  background-color: #3c3c3c;
  display: inline;
  padding: 2px 8px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  border: 2px solid rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
}

.mission-list .mission-entry .mission-status {
  font-size: 0.9rem;
  font-style: italic;
  color: #2cbc55;
  display: inline;
  padding: 2px 8px;
}

.mission-list .mission-entry .mission-reward span {
  color: #fff;
}

.mission-list .mission-entry.done .mission-reward {
  color: #fff;
  background-color: #2cbc4a;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(29, 126, 57, 0.5);
  -moz-box-shadow: 0px 0px 10px 0px rgba(29, 126, 57, 0.5);
  box-shadow: 0px 0px 10px 0px rgba(29, 126, 57, 0.5);
}

.deal-offer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.deal-img .huge-icon {
  margin: -36px 0 -20px;
}

.deal-desc {
  font-size: 28px;
  line-height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.deal-desc span {
  font-weight: 300;
  color: #ffb600;
  font-size: 22px;
}

.arena-image1 {
  background: url("../images/highlights-banner.jpg") no-repeat top center #262626;
}

.arena-image0 {
  background: url("../images/rangliste0.jpg") no-repeat top center #262626;
}

.mission-image {
  background: url("../images/mission.jpg") no-repeat top center #262626;
}

.inner-content {
  width: calc(100% - 340px);
  position: relative;
}

.inner-sidebar {
  flex: 0 0 340px !important;
  max-width: 340px;
  width: 30%;
  min-width: 340px;

  position: sticky;
  height: calc(100vh - 150px);
  top: 135px;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 8px;
}

.betslip-footer {
  position: sticky;
  bottom: 0;
  background: #1d1d1d;
  color: #000;
  -moz-border-radius-bottomright: 8px;
  -webkit-border-bottom-right-radius: 8px;
  border-bottom-right-radius: 8px;
  -moz-border-radius-bottomleft: 8px;
  -webkit-border-bottom-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.betslip-action {
  padding: 5px 15px 15px 15px;
  -moz-border-radius-bottomright: 8px;
  -webkit-border-bottom-right-radius: 8px;
  border-bottom-right-radius: 8px;
  -moz-border-radius-bottomleft: 8px;
  -webkit-border-bottom-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.betslip-action .btn {
  margin-top: 0px;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.25) !important;
}

.all-matches-link {
  background-color: #FFF;
  text-align: right;
  padding: 14px 20px 14px 12px;
  display: block;
  cursor: pointer;
  font-weight: 500;
}

.badge-cnt {
  position: absolute;
  top: 0;
  right: 0;
  background: #f79000;
  color: #fff;
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  border-radius: 50%;
  box-shadow: 0 0 1px #333;
  font-family: "Oswald", sans-serif;
  font-weight: 700;
}

.coin-boosters,
.xp-boosters,
.coin-shop {
  margin-bottom: 30px;
}

.league-ranks {
  margin-bottom: 15px;
}

.coin-boosters [class*="col"] .tigercard,
.xp-boosters [class*="col"] .tigercard,
.coin-shop [class*="col"] .tigercard,
.league-ranks [class*="col"] .tigercard {
  margin-right: 10px;
}

.league-ranks .col-2 .tigercard {
  margin-right: 10px;
}

.coin-boosters [class*="col"]:last-child .tigercard,
.xp-boosters [class*="col"]:last-child .tigercard,
.coin-shop [class*="col"]:last-child .tigercard,
.league-ranks [class*="col"]:last-child .tigercard {
  margin-right: 0px;
}

.league-ranks .tigercard {
  padding: 0px;
  height: 100%;
}

.league-ranks .tigercard:hover {
  cursor: pointer;
}

.modal-dialog .tigercard .huge-icon {
  top: 0px !important;
  position: relative !important;
}

.modal-dialog .tigercard .card-name {
  height: auto;
}

.tigercard {
  background-color: #fff;
  padding-top: 20px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  padding: 15px;
  transition: all 0.15s ease-in-out;
  border: 0px;
  position: relative;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.25);
}

.dark-card {
  background: #262626;
}

.dark-card .card-reward,
.dark-card .rank-reward {
  color: #fff !important;
}

.tigercard.current {
  background-color: #262626;
}

.tigercard:hover {
  box-shadow: inset 0px 0px 0px 4px #ffb600;
}

.tigercard .btn {
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
  font-size: 18px;
  font-weight: 700;
}

.shop-deal {
  background: #d9d9d9;
  border-radius: 100px;
  color: #000;
  position: absolute;
  font-size: calc(100vw * 0.011);
  line-height: calc(100vw * 0.015);
  font-weight: 300 !important;
  text-align: center;
  top: 22px;
  padding: 5px 8% 5px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  margin: 0 8%;
}

.shop-deal-special {
  background: #ffb600;
}

.shop .card-name {
  padding: 0 12px 13px;
}

.shop .huge-icon {
  width: 200px;
}

.tigercard .card-element,
.tigercard .rank-element {
  flex: 1 1 0;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}

.card-name {
  font-size: calc(100vw * 0.014);
  line-height: calc(100vw * 0.015);
  padding: 0 6px 13px;
  text-align: center;
  height: 90px;
}

.tigercard .rank-element.rank-bar {
  display: none;
  max-height: 80px;
  padding: 20px;
}

.tigercard .rank-icon {
  padding-top: 30px;
}

.rank-icon.disabled,
.card-reward.disabled {
  opacity: 0.3;
}

.tigercard .card-reward,
.tigercard .rank-reward {
  padding-bottom: 24px;
  font-size: calc(100vw * 0.0145);
  line-height: calc(100vw * 0.018);
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
  color: #262626;
  flex-direction: column;
  text-align: center;
}

.tigercard .card-reward-amount,
.tigercard .rank-reward-amount {
  flex: 1 1 0;
  position: relative;
  float: left;
  font-weight: 700;
}

.tigercard .card-reward-amount span,
.tigercard .rank-reward-amount span {
  color: #999;
  font-weight: 300;
}

.tigercard .rank-reward-headline {
  flex: 1 1 0;
  font-weight: 300;
  padding-top: 20px;
}

.tigercard.current .rank-reward-headline {
  color: #fff;
}

.tigercard .rank-reward-amount {
  flex: 1 1 0;
  position: relative;
  float: left;
}

.tigercard.current .rank-reward-amount {
  color: #fff;
}

.tigercard .rank-reward-amount span {
  color: #999;
  font-weight: 300;
}

.tigercard.current .rank-reward-amount span {
  color: #ffb600;
}

.tigercard.current .rank-reward-amount .rank-info {
  color: #fff;
}

.content-header {
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
  background-color: #262626;
  color: #fff;
  flex: 1 1 0;
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 30px;
  margin-bottom: 15px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}

.content-header a:hover,
.content-header a:focus,
.content-header a:active {
  color: #f79000;
  text-decoration: underline;
}

.content-header.shop {
  padding-right: 30px;
  display: flex;
}

.content-header .content-header-link {
  display: flex;
  -ms-flex-align: center !important;
  align-items: center !important;
  font-size: 18px;
  line-height: 18px;
  color: #fff;
  text-decoration: none;
  height: 90px;
  padding-right: 10px;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
}

.content-header .content-header-link:hover,
.content-header
  .content-header-link:focus
  .content-header
  .content-header-link:active {
  color: #fff;
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.1);
}

.user-icon {
  width: 30px;
  height: 30px;
  border-radius: 100px;
  margin: 0 12px 0 0;
}

.user1 {
  background: url("../images/avatar.png") no-repeat top center;
  background-size: 115%;
}

.content-header.toplist {
  padding-right: 30px;
  display: flex;
}

.list-box {
  -moz-border-radius-bottomright: 8px;
  -webkit-border-bottom-right-radius: 8px;
  border-bottom-right-radius: 8px;
  -moz-border-radius-bottomleft: 8px;
  -webkit-border-bottom-left-radius: 8px;
  border-bottom-left-radius: 8px;
  overflow: hidden;
}

.list-box.no-filter {
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  border-radius: 8px !important;
}

.list-box a:hover,
.list-box a:focus {
  text-decoration: none;
}

.list-box .category {
  background-color: #eadfe6;
}

.list-box .special-bets {
  padding: 15px 15px 30px 15px;
  background-color: #dbdbdb;
  box-sizing: border-box;
  display: none;
}

.list-box .special-bets.open {
  display: block;
}

.list-box.toplist {
  margin-right: 20px;
  padding: 0;
  list-style: none;
  background-color: #dbdbdb;
}

.list-box.toplist .legend {
  margin-bottom: 0px;
  border-radius: 0px;
  border-bottom: 0px;
}

.list-box.toplist .legend div,
.list-box.toplist .legend div,
.list-box.toplist .legend div {
  color: #878787;
  min-height: auto;
  padding: 10px;
  font-weight: normal;
  text-transform: none;
  font-size: 14px;
  font-family: 'Roboto', Verdana, Arial;
}

.list-box.toplist .legend .legend-headline-rank {
  max-width: 10%;
}

.list-box.toplist .legend .legend-headline-username {
  justify-content: left;
}

.list-box.toplist .legend .legend-headline-img {
  max-width: 30%;
}

.list-box.toplist .legend .legend-headline-img .medium-icon {
  position: absolute;
  transform: scale(0.4);
}

.list-box.toplist .toplist-rank {
  max-width: 10%;
  font-weight: 700;
  font-size: 16px;
}

.list-box ul.toplist:last-of-type {
  margin: 0px;
}

.list-box.toplist li {
  display: flex;
  flex-direction: row;
}

.list-box.toplist li div {
  flex: 1 1 0;
  background: #fff;
  margin: 1px 1px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50px;
  padding: 0 15px;
  line-height: 17px;
  color: #262626;
}

.list-box.toplist li:first-child div {
  margin-top: 0px;
}

.list-box.toplist li div:last-of-type {
  margin-right: 0;
}

.list-box.toplist .toplist-user {
  justify-content: left;
  font-size: 16px;
}

.list-box.toplist .toplist-result {
  max-width: 30%;
  font-size: 16px;
}

.list-box.toplist .toplist-rank {
  max-width: 10%;
  font-weight: 700;
  font-size: 16px;
}

.toplist-image-xp {
  background: url("../images/rangliste1.jpg") no-repeat top center #262626;
}

.toplist-image-quote {
  background: url("../images/rangliste2.jpg") no-repeat top center #262626;
}

.toplist-image-coins {
  background: url("../images/rangliste3.jpg") no-repeat top center #262626;
}

.toplist-header {
  color: #fff;
  min-height: 300px;
  position: relative;
  text-align: center;
  padding: 218px 0 18px;
  box-sizing: border-box;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
  margin-right: 20px;
  margin-bottom: 10px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}

.toplist-header-gradient {
  width: 100%;
  height: 200px;
  position: absolute;
  bottom: 0;
  background: linear-gradient(
    to bottom,
    rgba(38, 38, 38, 0) 0%,
    rgba(38, 38, 38, 1) 60%,
    rgba(38, 38, 38, 1) 80%
  );
}

.toplist-header-text {
  position: relative;
  z-index: 1;
}

.toplist-header h2 {
  font-size: calc(100vw * 0.023);
  line-height: calc(100vw * 0.026);
  margin: 0 0 6px;
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}

.toplist-header h2 span {
  color: #ffb600;
}

.switcher {
  max-width: 18%;
  min-width: 18%;
}

.switcher .btn-secondary {
  color: #fff;
  background-color: #454545;
  border-color: #454545;
  font-weight: 600;
  text-decoration: none;
}

.switcher .btn-secondary:hover,
.switcher .btn-secondary:focus,
.switcher .btn-secondary:active {
  text-decoration: none;
}

.content-header h3 {
  font-weight: 700;
  margin: 0;
  line-height: 36px;
  padding: 10px 0;
  font-size: 30px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased !important;
  -moz-font-smoothing: antialiased !important;
  text-rendering: optimizelegibility !important;
  letter-spacing: 0.03em;
}

.content-header h1 {
  font-weight: 700;
  margin: 0;
  line-height: 50px;
  padding: 10px 0;
  font-size: 44px;
}

.content-header h1 span {
  font-weight: 300;
  color: #ffb600;
}

.content-header.league h1 {
  font-weight: 700;
  margin: 0;
  line-height: 50px;
  padding: 10px 0;
  font-size: 44px;
}

.content-header.league h1 span {
  font-weight: 300;
  color: #ffb600;
}

.content-header-headline {
  flex: 1 1 0;
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 30px;
}

.league-header-headline {
  flex: 1 1 0;
  display: flex;
  justify-content: left;
  align-items: center;
}

.league-info {
  background: #323232;
  border-radius: 6px;
  max-width: 40%;
  min-width: 40%;
  margin: 26px;
  padding: 11px 20px;
  color: #aaa;
  line-height: 22px;
  font-size: 14px;
  font-family: 'Roboto', Verdana, Arial;
  text-transform: none;
}

.league-info-headline {
  color: #fff;
  font-weight: bold;
  display: block;
}

.league-info.mobile {
  display: none;
  background-color: #262626;
  margin: 0;
  min-width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  text-align: center;
  padding: 15px;
  border-radius: 8;
}

.league-big-bar {
  background: #262626;
  width: 100%;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 20px;
  box-sizing: border-box;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}

.league-big-bar .league-now,
.league-big-bar .league-promotion {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.league-big-bar .league-change {
  padding-left: 12px;
  font-size: 22px;
  line-height: 26px;
}

.league-big-bar .league-change span.line-1 {
  display: block;
  font-weight: 300;
  color: #ffb600;
}

.league-big-bar .league-change span.line-2a {
  display: inline-block;
}

.league-big-bar .league-change span.line-2b {
  display: block;
  font-size: 16px;
}

.league-big-bar .progress {
  height: 50px !important;
  border-radius: 25px;
  border-width: 3px;
}

.league-big-bar .progress .progress-info-left,
.league-big-bar .progress .progress-info-right {
  font-size: 24px;
  line-height: 43px;
}

.modal .feedback-icon {
  color: #28a745;
  font-size: 66px;
}

.btn-link {
  color: #f79000;
}

.btn-link:hover {
  color: #262626;
}

.loading .modal-backdrop {
  background: #000 !important;
  opacity: 0.85 !important;
}

.modal-backdrop {
  background: rgba(0, 0, 0, 0.9) !important;
  background-attachment: fixed !important;
  backdrop-filter: blur(0.2rem);
  -webkit-backdrop-filter: blur(0.2rem);
  opacity: 1 !important;
}

.modal {
  padding-right: 0px !important;
}

.modal-dialog.system p {
  color: #888;
  text-align: center;
  line-height: 22px;
  margin: 0 auto;
}

.modal-dialog.system.create p,
.modal-dialog.system.change p {
  text-align: left;
  line-height: normal;
  margin: auto;
}

.modal-header .close {
  font-size: 2.5rem;
  margin: -1.6rem -1rem -1rem auto;
  text-shadow: 0 1px 0 #fff;
  color: #fff;
  z-index: 1;
}

.modal-content {
  border-radius: 0.6rem;
  overflow: hidden;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.modal-footer {
  border-top: 0px;
}

.close {
  font-weight: 400;
}

.modal-dialog.register .modal-header,
.modal-dialog.login .modal-header,
.modal-dialog.system .modal-header {
  background: url("../images/profile-background.jpg");
  background-repeat: no-repeat;
  background-position: top -14px center;
  width: 100%;
  height: 100px;
  background-size: 100%;
  border: 0px;
}

.modal-dialog.system.won .modal-header,
.modal-dialog.system.achievement .modal-header,
.modal-dialog.system.arena .modal-header,
.modal-dialog.system.league .modal-header {
  background-position: bottom center;
  width: 100%;
  height: 220px;
  background-size: cover;
  border: 0px;
  overflow: hidden !important;
}

.modal-dialog.system.won .modal-header .huge-icon,
.modal-dialog.system.achievement .modal-header .huge-icon,
.modal-dialog.system.arena .modal-header .huge-icon,
.modal-dialog.system.league .modal-header .huge-icon {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 73px;
  width: 200px;
}

.modal-dialog.system.won .subline,
.modal-dialog.system.achievement .subline,
.modal-dialog.system.arena .subline,
.modal-dialog.system.league .subline {
  font-size: 16px;
}

.modal-dialog.system .modal-header .glamour-container {
  width: 100%;
  position: absolute;
  left: 0px;
  top: -53px;
  right: 0px;
  height: 344px;
}

.starrays {
  background: url("../images/starrays-white.png");
  background-position: center center !important;
  background-repeat: no-repeat;
  width: 346px;
  height: 344px;
  animation: rotation 12s infinite linear;
  margin-left: auto;
  margin-right: auto;
}

.starrays.gold {
  background: url("../images/starrays.png");
}

.starrays.purple {
  background: url("../images/starrays-purple.png");
}

.starrays.red {
  background: url("../images/starrays-red.png");
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.modal-dialog.system.won .card.gain,
.modal-dialog.system.achievement .card.gain,
.modal-dialog.system.arena .card.gain,
.modal-dialog.system.league .card.gain {
  background: linear-gradient(0deg, #141414 0%, #303030 100%);
  padding: 10px;
  border-top: 2px solid rgba(0, 0, 0, 0.5);
  border-left: 2px solid rgba(0, 0, 0, 0.5);
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  border-right: 2px solid rgba(255, 255, 255, 0.2);
  border-width: 4px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.55) !important;
  border-radius: 100px;
  overflow: hidden;
}

.modal-dialog.system.won .card.gain:after,
.modal-dialog.system.achievement .card.gain:after,
.modal-dialog.system.arena .card.gain:after,
.modal-dialog.system.league .card.gain:after {
  content: "";
  position: absolute;
  top: -315%;
  left: -280%;
  width: 348px;
  height: 463%;
  opacity: 1;
  transform: rotate(30deg);
  background: rgba(255, 255, 255, 0);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.13) 82%,
    rgba(255, 255, 255, 0.5) 96%,
    rgba(255, 255, 255, 0)
  );
  animation-name: shine;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-direction: reverse;
}

@keyframes shine {
  100% {
    left: -280%;
  }
  30% {
    left: -280%;
  }
  0% {
    left: 140%;
  }
}

.modal-dialog.system .card.gain.single .gain-label {
  font-size: 24px;
}

.gain-label {
  text-transform: uppercase;
  font-size: 20px;
  font-size: 18px;
  font-family: "Oswald", sans-serif;
  font-weight: 550;
  color: #fff;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.4);
  overflow-wrap: anywhere;
  text-align: center;
}

.placement-highlight-large {
  font-size: 2.2rem;
  font-family: Oswald;
  line-height: 3.2rem;
  display: block;
  font-weight: bold;
  color: #212529;
  text-transform: uppercase;
}

.placement-highlight-medium {
  font-size: 1.9rem;
  font-family: Oswald;
  line-height: 2.1rem;
  display: block;
  font-weight: normal;
  color: #212529;
  text-transform: uppercase;
}

.placement-highlight-small {
  font-size: 1.5rem;
  font-family: Oswald;
  line-height: 1.8rem;
  display: block;
  font-weight: normal;
  color: #212529;
  text-transform: uppercase;
}

.modal-dialog.system .modal-header .huge-icon {
  filter: grayscale(0) !important;
  z-index: 1;
}

.modal-dialog.system.betslip .modal-content {
  background-color: #eadfe6;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.modal-dialog.system.betslip .modal-header {
  background-image: none;
  height: auto;
}

.modal-dialog.system.betslip .modal-header .close {
  color: #000;
  background: #fff;
  line-height: 0.6;
  padding: 1rem 0.8rem 0.6rem 0.8rem;
}

.modal-dialog.system.betslip .modal-body {
  padding-top: 18px;
  padding-bottom: 35px;
}

.modal-dialog.system.betslip .modal-body .list-box {
  overflow: hidden;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}

.modal-dialog.system.betslip .modal-body .list-box .entry {
  position: relative;
  margin-bottom: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
}

.modal-dialog.system.betslip .modal-body .list-box .entry:first-child {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  border: 1px solid #e5e1e9;
}

.modal-dialog.register .huge-icon,
.modal-dialog.login .huge-icon,
.modal-dialog.system .huge-icon {
  top: 18px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

.modal-dialog.system .modal-body,
.modal-dialog.register .modal-body {
  padding-top: 98px;
}

.modal-dialog.system.won .modal-body,
.modal-dialog.system.achievement .modal-body,
.modal-dialog.system.arena .modal-body,
.modal-dialog.system.league .modal-body {
  padding-top: 40px;
  overflow: hidden;
}

.modal-dialog.system.arena .modal-body,
.modal-dialog.system.league .modal-body {
  background-image: url("../images/confetti.svg");
  background-repeat: repeat-x;
  background-position: top center;
  background-size: 190%;
}

.modal-dialog.system.league.down .modal-body,
.modal-dialog.system.league.hold .modal-body {
  background-image: none;
}

.no-confetti {
  background-image: none !important;
}

.modal-dialog.register .btn-primary,
.modal-dialog.login .btn-primary,
.modal-dialog.system .btn-primary {
  margin-top: 8px;
  padding: 15px 30px;
  font-size: 26px;
  box-shadow: 0px 14px 27px -22px rgba(0, 0, 0, 1);
  width: 100%;
  display: block;
}

.modal-dialog.system .modal-footer .btn {
  margin-top: .25rem;
  padding: 12px 20px;
  font-size: 1rem;
  width: auto;
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
  box-shadow: none;
}

.modal-dialog.login .btn-primary {
  width: 100%;
  padding: 0.5rem 1rem;
}

.modal-dialog.register .btn-google,
.modal-dialog.login .btn-google,
.modal-dialog.register .btn-facebook,
.modal-dialog.login .btn-facebook {
  background-color: #fff;
  border: 2px solid #e9e9e9;
  box-shadow: 0px 14px 27px -22px rgba(0, 0, 0, 1) !important;
}

.modal-dialog.login .btn-google:hover,
.modal-dialog.register .btn-google:hover,
.modal-dialog.login .btn-facebook:hover,
.modal-dialog.register .btn-facebook:hover {
  background-color: #e5e5e5;
}

.modal-dialog.login .btn-google i,
.modal-dialog.register .btn-google i,
.modal-dialog.login .btn-facebook i,
.modal-dialog.register .btn-facebook i {
  margin-right: 10px;
}

.modal-dialog.system.create .dropdown-header,
.modal-dialog.system.change .dropdown-header {
  background-color: #e5e5e5;
}

.modal-dialog.system.create .modal-header,
.modal-dialog.system.change .modal-header {
  height: auto;
  background: none;
  background-color: #262626;
  color: #FFF;
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
}

.modal-dialog.system.create .dropdown-toggle,
.modal-dialog.system.change .dropdown-toggle {
  background-color: #e5e5e5;
  color: #212529;
  border: none;
}

.modal-dialog.system.create .dropdown-toggle:focus,
.modal-dialog.system.change .dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem #ffb600;
}

.modal-dialog.system.create .modal-footer,
.modal-dialog.system.change .modal-footer {
  background-color: #f5f6f6;
}


.modal-dialog.system.create .modal-body,
.modal-dialog.system.change .modal-body {
  padding-top: 1rem;
}


.form-control {
  background: #e5e5e5 !important;
  border: none;
  color: #555;
}

.form-control:focus {
  box-shadow: 0 0 0 0.2rem #ffb600;
}

.modal-dialog.register .form-check,
.modal-dialog.login .form-check {
  color: #888888;
  font-size: 13px;
}

.register-bonus {
  background: linear-gradient(to right, #0e0e0e 0%, #178ed3 50%, #0e0e0e 100%);
  width: 100%;
  border-radius: 8px;
  border: 2px solid #135880;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.7);
  font-size: 20px;
  color: #fff;
  display: inline-block;
  margin: 0 auto;
  line-height: 50px;
  text-align: center;
}

.register-bonus .medium-icon {
  margin-top: -10px;
  margin-bottom: -10px;
}

.btn-primary {
  background: rgb(248, 127, 11);
  background-image: linear-gradient(
    to right,
    #ff6b00 0%,
    #ffb600 51%,
    #ff6b00 100%
  );
  transition: 0.5s;
  background-size: 200% auto;
  color: #fff;
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.6);
  cursor: pointer;
  border: 2px solid #ff9a00;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased !important;
  -moz-font-smoothing: antialiased !important;
  text-rendering: optimizelegibility !important;
  letter-spacing: 0.03em;
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
}

.btn-primary:hover {
  border: 2px solid #ffb600;
  background-position: right center;
}

.btn-primary.focus,
.btn-primary:focus {
  border-color: #f79b43;
  box-shadow: 0 0 0 0.2rem #8f521d;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  border-color: #f79b43;
  box-shadow: 0 0 0 0.2rem rgba(247, 85, 67, 0.5) !important;
}

.btn-primary.disabled,
.btn-primary:disabled {
  border-color: rgba(255, 191, 36, 0.5);
  opacity: 0.3;
  cursor: default;
}

.tigerfont {
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}

.main-bg-color {
  background-color: #363636;
}

.navbar {
  background-color: #262626;
  justify-content: flex-start;
}

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

.content-area.club {
  margin-left: -40px;
  margin-right: -40px;
  margin-bottom: -40px;
  margin-top: -35px;
}

.content-area.club .coming-soon {
  background-color: #000;
  background-image: url("../images/coming-soon.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 100%;
  min-height: calc(100vh - 140px);
  position: relative;
  box-sizing: border-box;
}

.main-content {
  background-image: url("../images/stadium-background.jpg");
  background-repeat: no-repeat;
  background-position: center top;
  background-attachment: fixed;
  min-height: calc(100vh - 69px);
  box-sizing: border-box;
  position: relative;
  width: 100% !important;
  padding-top: 135px !important;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
}

/* BETSLIP BOX / WETTSCHEIN */

.inner-sidebar .betslip {
  background-color: #262626;
  color: #fff;
  z-index: 0;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}

.inner-sidebar .betslip a.btn:hover {
  color: #fff;
}

.inner-sidebar .betslip-header {
  text-align: center;
  height: 88px;
  z-index: 1;
  background-color: #262626;
  -moz-border-radius-topleft: 8px;
  -webkit-border-top-left-radius: 8px;
  border-top-left-radius: 8px;
  -moz-border-radius-topright: 8px;
  -webkit-border-top-right-radius: 8px;
  border-top-right-radius: 8px;
}

.inner-sidebar .betslip-header h2 {
  font-size: 28px;
  padding: 13px 0 0;
  font-weight: 700;
  margin: 0px;
  color: #fff;
}

.betslip-countdown {
  font-weight: 300;
  font-size: 19px;
  margin: -1px 0 14px;
  display: inline-block;
  color: #999;
  letter-spacing: 1px;
}

.betslip.empty .betslip-tabs {
  display: none;
}

.betslip-tabs {
  background-color: #262626;
  display: flex;
  flex-direction: row;
  position: sticky;
  top: 0px;
  z-index: 1;
  -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.35);
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.35);
}

.betslip-tab {
  flex: 1 1 0;
  display: block;
  height: 45px;
  text-align: center;
  background: #1d1d1d;
  color: #fff;
  line-height: 45px;
  margin-right: 1px;
  font-weight: 500;
}

.betslip-tab:last-child {
  margin-right: 0px;
}

.betslip-tab.disabled {
  background: #1d1d1d;
  color: #444;
  cursor: default;
}

.betslip-tab.disabled:hover {
  background: #1d1d1d;
  color: #444;
}

.betslip-tab.active {
  background-color: #0e0e0e;
  color: #ffb600;
  text-decoration: none;
  text-shadow: 0px 0px 8px rgba(255, 182, 0, 0.75);
}

.betslip-tab.active:hover {
  color: #ffb600;
  background-color: #0e0e0e;
}

.betslip-tab:hover {
  background: #313131;
  color: #fff;
  text-decoration: none;
}

.betslip-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
}

.betslip-entries {
  overflow: auto;
}

.betslip.empty .betslip-footer {
  display: none;
}

.betslip-options {
  background-color: #181818;
  flex: 1 1 0;
  display: flex;
  padding: 10px 15px;
  -webkit-box-shadow: 0px -5px 8px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px -5px 8px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px -5px 8px 0px rgba(0, 0, 0, 0.2);
}

.system-options {
  display: flex;
  background-color: #262626;
  padding: 10px 15px;
  -webkit-box-shadow: 0px -5px 8px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px -5px 8px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px -5px 8px 0px rgba(0, 0, 0, 0.2);
  margin: 0px;
}

.system-options .form-control,
.modal .mobile-betslip .form-control {
  background: #303030 !important;
  border: 0px;
  color: #c3c3c3;
}

.system-options .form-control:focus {
  box-shadow: none;
}

.count-bets {
  display: flex;
  color: #fff;
  font-size: 12.5px;
  width: 80%;
  align-items: center;
}

.betslip-delete-all {
  display: flex;
  text-decoration: underline;
  color: #fff;
  font-size: 12.5px;
  width: 20%;
  justify-content: right;
  align-items: center;
}

.betslip-delete-all:hover {
  color: #ffb600 !important;
}

.betslip-custom-headline {
  display: block;
  font-size: 22px;
  line-height: 26px;
  padding-top: 12px;
  color: #fff;
}

.betslip-custom {
  flex: 1 1 0;
  text-align: center;
  position: relative;
  -moz-border-radius-bottomright: 8px;
  -webkit-border-bottom-right-radius: 8px;
  border-bottom-right-radius: 8px;
  -moz-border-radius-bottomleft: 8px;
  -webkit-border-bottom-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.betslip-bet-border {
  width: 100%;
  height: 1px;
  background: linear-gradient(
    to right,
    rgba(38, 38, 38, 0) 0%,
    rgba(82, 82, 82, 1) 50%,
    rgba(38, 38, 38, 0) 100%
  );
}

.betslip-outcome {
  list-style: none;
  margin: 0;
  padding: 10px 15px;
  flex-direction: row;
  display: flex;
}

.betslip-outcome li {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: #9c9c9c;
}

.betslip-outcome li.outcome-xp {
  width: 50%;
  align-items: center;
  margin-right: 5px;
}

.betslip-outcome li.outcome-coins {
  width: 50%;
  align-items: center;
}

.betslip-outcome li div {
  font-size: 19px;
  color: #fff;
}

.betslip-outcome li.outcome-xp .value,
.betslip-outcome li.outcome-coins .value {
  margin-right: 5px;
}

.betslip-outcome li.outcome-xp,
.betslip-outcome li.outcome-coins {
  overflow-wrap: anywhere;
  text-align: center;
}

.betslip-outcome li span.name {
  font-weight: 300;
  color: #ffb600;
}

.coins-einsatz {
  height: 40px;
  background: #303030;
  position: relative;
  margin-top: 12px;
  border-radius: 6px;
  overflow: hidden;
  margin: 15px;
}

.coins-einsatz a {
  width: 40px;
  height: 40px;
  line-height: 38px;
  background: #6a6a6a;
  float: left;
  color: #fff;
  font-size: 30px;
  padding-left: 1px;
  box-sizing: border-box;
}

.coins-einsatz input {
  text-align: center;
  float: left;
  width: calc(100% - 80px);
  border: none;
  height: 40px;
  line-height: 40px;
  box-sizing: border-box;
  padding: 0;
  font-size: 18px;
  background: none;
  color: #fff;
  text-align: right;
  padding-right: 62px;
}

.coins-einsatz input::-webkit-outer-spin-button,
.coins-einsatz input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.coins-einsatz input[type="number"] {
  -moz-appearance: textfield;
}

.coins-einsatz input:hover,
.coins-einsatz input:focus {
  background: #181818;
}

.coins-einsatz a:hover,
.submit-bet:hover {
  background: #959595;
  color: #fff;
}

.coins-text {
  position: absolute;
  top: 7px;
  right: 52px;
  color: #ffb600;
  font-weight: 300;
  font-size: 18px;
}

.coins-einsatz a {
  width: 40px;
  height: 40px;
  line-height: 38px;
  background: #6a6a6a;
  float: left;
  color: #fff;
  font-size: 30px;
  padding-left: 1px;
  box-sizing: border-box;
}

.coins-einsatz a:hover,
.coins-einsatz a:focus {
  text-decoration: none;
}

.coins-einsatz .medium-icon {
  position: absolute;
  left: 30px;
  top: -15px;
  transform: scale(0.4);
}

.betslip .betslip-notice,
.mobile-betslip .betslip-notice {
  background-color: #313131;
  color: #868686;
  width: 100%;
  padding: 15px 10px;
  text-align: center;
  -moz-border-radius-bottomright: 8px;
  -webkit-border-bottom-right-radius: 8px;
  border-bottom-right-radius: 8px;
  -moz-border-radius-bottomleft: 8px;
  -webkit-border-bottom-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.mobile-betslip .betslip-notice {
  background-color: transparent;
  padding: 35px 10px;
}

.betslip .betslip-notice i,
.mobile-betslip .betslip-notice i {
  font-size: 3.2rem;
  margin-bottom: 10px;
}

.betslip .betslip-notice p,
.mobile-betslip .betslip-notice p {
  margin: 0;
}

.betslip-entry {
  width: 100%;
  border-bottom: 1px solid #323232;
  display: flex;
  box-sizing: border-box;
  position: relative;
  flex-wrap: wrap;
  overflow: hidden;
  padding: 10px 15px;
}

.betslip-entry:last-child {
  margin-bottom: 0px;
}

.betslip-info {
  display: flex;
  flex-direction: column;
  line-height: 19px;
  font-size: 13px;
  flex: 1 1 0;
  color: #868686;
}

.betslip-result {
  color: #fff;
}

.betslip-quote {
  max-width: 50px;
  min-width: 50px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  font-weight: normal;
}

.betslip-close {
  min-width: 40px;
  max-width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.betslip-close .medium-icon {
  background-position: -434px -154px;
  height: 54px;
}

.single-bet {
  width: 100%;
  height: 33px;
  box-shadow: inset 0px 2px 0px 0px #262626;
  position: relative;
  font-size: 13px;
  line-height: 33px;
  padding-left: 30px;
  color: #fff;
}

.single-bet .medium-icon {
  position: absolute;
  top: -19px;
  transform: scale(0.37);
  left: -23px;
}

.single-bet input {
  height: 33px;
  border: none;
  background: none;
  text-align: right;
  padding-right: 66px;
  width: 70%;
  float: right;
  box-sizing: border-box;
  font-size: 18px;
  color: #fff;
  box-shadow: inset 0px 2px 0px 0px #262626;
  border-radius: 5px;
}

.single-bet input:hover,
.single-bet input:focus {
  background: #1d1d1d;
}

.single-bet input::-webkit-outer-spin-button,
.single-bet input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.single-bet input[type="number"] {
  -moz-appearance: textfield;
}

.single-bet .coins-text {
  right: 14px;
  top: 1px;
}

/* BETSLIP BOX - ENDE */

.sidebar {
  background-color: #262626;
  position: fixed;
  display: block !important;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 0px; /* Height of navbar */
  margin-top: 100px;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.sidebar::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

.sidebar h2 {
  color: #fff;
  font-size: 19px;
  margin: 20px 0 0 20px;
  display: block;
  position: relative;
  z-index: 1;
}

.sidebar ul {
  margin: 10px 0;
  padding: 0;
  list-style: none;
}

.sidebar li a {
  display: block;
  position: relative;
  padding: 7px 28px 7px 62px;
  display: flex;
  flex-direction: column;
  color: #fff !important;
  font-size: 14px;
  box-sizing: border-box;
  line-height: 18px;
  text-decoration: none;
  cursor: pointer;
}

.sidebar li a:hover span {
  color: #fff;
}

.sidebar .medium-icon {
  position: absolute;
  display: block;
  left: -4px;
  top: -11px;
  transform: scale(0.66);
}

.quick-filter {
  margin-bottom: 25px !important;
}

.quick-filter li a:hover {
  background-color: #0e0e0e;
}

.quick-filter li a.active {
  color: #ffb600;
  background-color: #0e0e0e;
}

.filter-menu {
  margin-bottom: 25px !important;
}

.filter-menu.favourites li a.active {
  background-color: #0e0e0e !important;
}

.filter-menu li ul {
  background-color: #0e0e0e;
  margin: 0;
  padding-left: 0px;
  font-size: 13px;
}

.filter-menu li ul > li {
  line-height: 15px;
  padding: 6px 17px 6px 15px;
}

.filter-menu li ul > li.active {
  padding: 6px 12px 0px 12px;
}

.filter-menu li ul > li:hover {
  background-color: #2e2e2e;
}

.filter-menu li ul > li.active:hover {
  background-color: none !important;
}

.filter-menu li ul > li.active ul {
  margin-top: 6px;
  margin-bottom: 0px;
  margin-left: -12px;
  margin-right: -12px;
  padding-left: 12px;
  padding-right: 12px;
  background-color: #262626;
}

.filter-menu li ul li span {
  float: right;
  color: #ccc;
}

.filter-menu li a {
  background: url("../images/arrow-down.png") top 12px right 15px no-repeat;
  background-size: 10%;
}

.filter-menu li a:hover {
  background-color: #0e0e0e !important;
}

.filter-menu li.active a {
  background: url("../images/arrow-up.png") top 15px right 15px no-repeat #0e0e0e;
  background-size: 10%;
}

/* Favoriten */

.favourites-subentry {
  cursor: pointer;
}

.favourites-subentry ul.active {
  display: block;
}

.favourites-amount {
  color: #7f7f7f;
  font-size: 13px;
}

.sidebar .filter-menu.favourites li a {
  padding: 7px 20px 7px 20px;
}

.sidebar .filter-menu.favourites li.subscription a {
  background: none;
}

.sidebar .filter-menu.favourites li ul > li {
  padding: 6px 20px 6px 20px;
}

.all-sports-country {
  cursor: pointer;
}

.all-sports-country ul.active {
  display: block;
}

.caret::before {
  content: "\25B6";
  font-size: 12px;
  color: #ffb600;
  display: inline-block;
  margin-right: 6px;
}

.caret-down::before {
  transform: rotate(90deg);
}

.sidebar .sub-filter {
  padding: 0px;
  display: none;
  color: #fff;
}

.sidebar .sub-filter > li > ul > li {
  margin-left: -12px;
  margin-right: -12px;
  padding-left: 24px;
  padding-right: 12px;
}

.sidebar .sub-filter > li > ul > li.active {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 6px 12px 6px 24px;
}

.sidebar .sub-filter > li > ul > li:hover {
  background-color: #2e2e2e;
}

.sidebar .sub-filter > li.active:hover {
  background-color: none;
}

.sidebar .sub-filter.active {
  display: block;
}

.sports-amount {
  color: #7f7f7f;
  font-size: 13px;
}

.flag-football-germany {
  position: absolute;
  top: 8px;
  left: 8px;
  width: 50px;
  height: 37px;
}

.flag-football-england {
  position: absolute;
  top: 8px;
  left: 8px;
  width: 50px;
  height: 37px;
}

.flag-football-spain {
  position: absolute;
  top: 8px;
  left: 8px;
  width: 50px;
  height: 37px;
}

.alt-footer {
  text-align: center;
  color: #262626;
  margin-bottom: 80px;
  margin-top: 15px;
  padding: 15px;
}

.alt-footer a {
  color: #262626;
}

.footer {
  background: linear-gradient(
    to right,
    rgba(54, 54, 54, 1) 0%,
    rgba(38, 38, 38, 1) 10%
  );
  color: #fff;
}

.footer a {
  color: #fff;
}

@media (max-width: 767.98px) {
  .sidebar {
    top: 5rem;
  }
}

.sidebar-sticky {
  position: fixed;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0px;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link.active {
  color: #007bff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}

.navbar-brand {
  justify-content: flex-start;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 1rem;
  background-color: #262626;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.25);
  height: 100px;
  display: flex;
  align-items: center;
}

.navbar-brand:hover {
  background-color: #353535;
}

.navbar-brand .claim {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto', Verdana, Arial;
  color: #aaa;
  font-size: 14.5px;
  text-transform: lowercase;
  font-weight: 400;
  letter-spacing: 1px;
  display: none;
}

.navbar .navbar-toggler {
  top: 0.25rem;
  right: 1rem;
}

.navbar .form-control {
  padding: 0.75rem 1rem;
  border-width: 0;
  border-radius: 0;
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1);
}

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.25);
}

.card.tippspiel-teaser {
  background-color: #1a1a1a;
  background: rgb(45,64,128);
  background: linear-gradient(0deg, rgba(45,64,128,1) 0%, rgba(26,26,26,1) 50%);
  color: #FFF;
  box-shadow: 0 0.5rem 1rem rgb(0,0,0,0.25) !important;
  border: 0px;
}

.card.tippspiel-teaser.group {
  background-color: #1a1a1a;
  background: rgba(56,56,56,1);
  background: linear-gradient(0deg, rgba(56,56,56,1) 0%, rgba(26,26,26,1) 50%);
  color: #FFF;
  box-shadow: 0 0.5rem 1rem rgb(0,0,0,0.25) !important;
  border: 0px;
}

.card.tippspiel-teaser .card-title {
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
  font-size: 40px;
  margin: 0 0 25px;
  color: #fff;
  font-weight: 700;
  text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.4), 0px 8px 13px rgba(0, 0, 0, 0.1),
    0px 18px 23px rgba(0, 0, 0, 0.1);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased !important;
  -moz-font-smoothing: antialiased !important;
  text-rendering: optimizelegibility !important;
  letter-spacing: 0.03em;
  font-style: italic;
  text-shadow: 0px 0px 15px rgba(150, 150, 150, 1);
}

.card.tippspiel-teaser .card-text {
  margin-bottom: 25px;
  text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.4), 0px 8px 13px rgba(0, 0, 0, 0.1),
  0px 18px 23px rgba(0, 0, 0, 0.1);
}

.card.tippspiel-teaser .card-body {
  padding: 2.25rem 2.25rem 2.25rem 2.7rem;
}

.card.tippspiel-teaser .btn-primary {
  font-weight: 700;
}

.tippspiel-block {
  background: #1a1a1a;
  color: #FFF;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 15px;
  box-shadow: 0 0.5rem 1rem rgb(0,0,0,0.25) !important;
}

.tippspiel-block .headline {
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
  font-size: 20px;
  padding: 15px;
  background-color: #363636;
  color: #FFF;
}

.tippspiel-list {
  list-style: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #FFF;
}

.tippspiel-list .tippspiel-entry {
  display: flex;
  flex-direction: row;
  padding: 13px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05) ;
  background-color: #1a1a1a;
}

.tippspiel-list .tippspiel-entry:hover {
  background-color: #202020;
  cursor: pointer;
}

.tippspiel-list .tippspiel-entry:hover .tippspiel-title {
  color: #ffb600;
}


.tippspiel-list .tippspiel-entry .tippspiel-content {
  margin-left: 10px;
}

.tippspiel-list .tippspiel-entry .tippspiel-title {
  color: #FFF;
  font-weight: 700;
  font-size: 1rem;
  margin-bottom: 5px;
}

.tippspiel-list .tippspiel-entry .tippspiel-body {
  color: #7f7f7f;
}

.tippspiel-list .tippspiel-icon .tippspiel-icon-inner {
  background-color: #262626;
  width: 50px;
  height: 50px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.35);
  overflow: hidden;
}

.tippspiel-list .tippspiel-icon .tippspiel-icon-inner img {
  width: 50px;
  height: 50px;
}

.tippspiel-group-header {
  background-color: #262626;
  background-image: url(../images/profile-background-bup.jpg);
  background-position: left center;
  margin-bottom: 15px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.5rem 1rem !important;
  border-radius: 8px;
  overflow: hidden;
  min-height: 180px;
  display: flex;
  padding-top: 30px;
  padding-bottom: 30px;
}

.tippspiel-group-header .tippspiel-group-header-title {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.tippspiel-group-header .tippspiel-group-header-title .inner {
  color: #FFF;
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
  font-size: 34px;
  background: rgb(0,0,0);
  background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(26,26,26,0) 100%);
  padding: 15px 25px 15px 25px;
  width: 100%;
  display: flex;
}

.tippspiel-group-header .tippspiel-group-header-title .inner .tippspiel-icon {
  width: 50px;
  height: 50px;
  margin-right: 15px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.35);
  overflow: hidden;
}

.tippspiel-group-header .tippspiel-group-header-title .inner .tippspiel-icon img {
  vertical-align:baseline;
}

.sub-navigation {
  background-color: #1a1a1a;
  border-radius: 8px;
  margin-bottom: 15px;
  overflow: hidden;
  box-shadow: 0 0.5rem 1rem rgb(0,0,0,0.25) !important;
}

.sub-navigation .nav-link {
  color: #FFF;
  padding: .75rem 1.45rem;
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
  font-size: 20px;
  -webkit-box-shadow: 1px 0px 0px 0px rgba(50, 50, 50, 0.45);
  -moz-box-shadow:    1px 0px 0px 0px rgba(50, 50, 50, 0.45);
  box-shadow:         1px 0px 0px 0px rgba(50, 50, 50, 0.45);
}

.sub-navigation .nav-link:hover {
  background-color: #202020;
}

.sub-navigation .nav-link.active {
  background-color: #262626;
  color: #ffb600;
}

.little-subnav {
  border-bottom: 1px solid #dbdbdb;
  margin-bottom: 1.5rem!important;
}

.little-subnav .nav-link {
  color: #262626;
  padding: 0.8rem 1rem;
  font-size: 14px;
  border-radius: 0px;
}

.little-subnav .nav-link.active {
  border-bottom: 3px solid #313131;
  font-weight: bold;
  background-color: #FFF;
  color: #262626;
}

.table-rules tbody tr:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}

.tippspiel-user-list {
  margin-bottom: 1.5rem!important;
}

.tippspiel-user-list tbody tr {
  cursor: pointer;
}

.table .header-box {
  display: block;
}

.table.wunschergebnis .position.down {
  color: #ed1f1f;
}

.table.wunschergebnis .position.up {
  color: #3bcc17;
}

.table.wunschergebnis:hover {
  cursor: default;
}

.table.wunschergebnis thead th {
  border-bottom: 2px solid #dee2e6;
}

.table.wunschergebnis td, .table.wunschergebnis th {
  border-bottom: 1px solid #dee2e6;
}

.table.tippabgabe .rowdetails > td {
  padding: 0px;
  background-color: #ededed;
}

.table.tippabgabe .rowdetails td {
  display: none;
}

.table.tippabgabe .rowdetails.open td {
  display: table-cell;
}

.table.tippabgabe tbody > tr {
  cursor: pointer;
}

.table.wunschergebnis tbody > tr {
  cursor: default;
}

table.tippabgabe .date {
  font-weight: bold;
}

table.tippabgabe .rowheader td {
  padding: 6px 7px 4px 7px;
  background-color: #dee2e6;
  font-weight: bold;
}

table.tippabgabe .rowfooter td {
  padding: 6px 7px 8px 7px;
}

table.tippabgabe .quote a {
  color: #212529;
}

table.tippabgabe .quote:hover a {
  background-color: #6c757d;
  color: #FFF;
  text-decoration: none;
}

.section-link {
  display: block;
  padding: 10px 12px;
  color: #262626;
  margin-bottom: 5px;
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  display:flex;
  justify-content:space-between;
  align-items: center;
}

.section-link:hover {
  text-decoration: none;
  background-color: #ececec;
}

.section-link i {
  align-self: center;
}

.content-area.tippspiel .dropdown-menu {
  min-width: 250px;
}

.content-area.tippspiel .nav-tabs .nav-link {
  color: #262626;
  font-weight: bold;
  margin-right: 5px;
  background-color: #f8f9fa;
  border-width: 1px;
  border-style: solid;
  border-color: #e9ecef #e9ecef #dee2e6;
  padding: .7rem 1rem;
}

.content-area.tippspiel .nav-tabs .nav-link.disabled {
  opacity: 0.35;
}

.content-area.tippspiel .nav-tabs .nav-link.dropdown {
  margin-right: 0px;
}

.content-area.tippspiel .nav-tabs .nav-link:hover {
  background-color: #e9ecef;
}

.content-area.tippspiel .nav-tabs .nav-link.active {
  background-color: #FFF;
  border-color: #e9ecef #e9ecef transparent;
}



.content-box {
  background-color: #FFF;
  border-radius: 8px;
  padding: 25px;
  margin-bottom: 30px;
  box-shadow: 0 0.5rem 1rem rgb(0,0,0,0.25) !important;
}

.content-box h3.headline {
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
  color: #262626;
  font-size: 30px;
  margin-bottom: 25px;
}

h5.section-headline {
  background-color: #f5f6f6;
  border-radius: 5px;
  padding: 7px 10px 7px 10px;
  font-size: 16px;
  font-weight: bold;
}



.panel {
  background-color: #FFF;
  border-radius: 8px;
  box-shadow: 0 0.5rem 1rem rgb(0,0,0,0.25) !important;
  overflow: hidden;
  margin-bottom: 1rem;
}

.panel .panel-head {
  font-size: 20px;
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
  padding: .75rem 1.25rem;
  background-color: #262626;
  color: #FFF;
}

.panel .panel-body {
  font-size: 14px;
  padding: .75rem 1.25rem;
}

.panel.invite-friend .panel-body {
  font-size: 14px;
  padding: .75rem;
}

.panel .panel-footer {
  font-size: 14px;
  padding: .75rem 1.25rem;
  background-color: #f5f6f6;
}

.panel.submenu .panel-body {
  padding: 0px;
}

.panel.submenu .list-group {
  border-radius: 0px;
}

.panel.submenu .list-group .list-group-item {
  border-left: 0px;
  border-right: 0px;
  text-transform: uppercase;
  font-weight: bold;
  color: #262626;
  font-size: 12px;
}

.panel.submenu .list-group .list-group-item:hover {
  border-bottom-width: 1px;
}

.panel.submenu .list-group .list-group-item.active {
  background-color: #ffb600;
  border-color: #ffb600;
  color: #1a1a1a;
}

.panel.submenu .list-group .list-group-item:last-child {
  border-bottom: 0px;
}

.panel.submenu .list-group .list-group-item:first-child {
  border-top: 0px;
}

.panel.submenu .list-group .list-group-item .badge {
  line-height: normal;
  background-color: #ffb600;
  color: #262626;
}

.panel.submenu .list-group .list-group-item.active .badge {
  background-color: #FFF;
}


.main-menu {
  display: flex;
  height: 100%;
  border-radius: 0px;
  -webkit-box-shadow: 0px -2px 7px 0px rgba(50, 50, 50, 0.35);
  -moz-box-shadow: 0px -2px 7px 0px rgba(50, 50, 50, 0.35);
  box-shadow: 0px -2px 7px 0px rgba(50, 50, 50, 0.35);
}

.main-menu a.list-group-item {
  flex: 1 1 0;
  text-align: center;
  height: 100px;
  justify-content: center;
  align-items: center !important;
  flex-direction: row;
  color: #262626;
  box-shadow: inset -1px 0 0 #d7d7d7;
  font-size: 22px;
  border-bottom: 4px solid;
  border-color: #d7d7d7;
  border-left: 0px;
  border-top: 0px;
  border-right: 0px;
}

.list-group-horizontal>.list-group-item+.list-group-item.active {
  margin-left: 0px;
}

.main-menu a.list-group-item.active {
  background-color: #f8f9fa;
  color: #262626;
  border-bottom: 4px solid #ffb600;
}

.main-menu a.list-group-item.active:hover,
.main-menu a.list-group-item.active:focus {
  border-bottom: 4px solid #ffb600 !important;
}

.main-menu a.list-group-item:first-child,
.main-menu a.list-group-item:last-child {
  border-radius: 0px !important;
}

.main-menu a.list-group-item.betslip {
  background: #262626;
  color: #fff;
  box-shadow: none;
  border-bottom-color: #1c1c1c;
}

.main-menu a.list-group-item.betslip:hover {
  border-bottom-color: #1c1c1c !important;
}

.main-menu a.list-group-item.betslip .badge {
  position: absolute;
  top: 3px;
  right: 3px;
  background: #f79000;
  color: #fff;
  width: 20px;
  height: 20px;
  line-height: 13px;
  text-align: center;
  border-radius: 50%;
  box-shadow: 0 0 1px #333;
  font-size: 11px;
}

.user-area {
  color: #fff;
  display: flex;
}

.user-area a:not(.btn):not(.profile-button) {
  color: #fbb603;
}

.user-area .btn-primary {
  color: #fff;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.7);
  border: 2px solid #ffbf24;
}

.user-area .btn:hover {
  filter: brightness(1.1);
}

.btn-fb {
  background: rgb(65, 74, 157) !important;
  background: linear-gradient(
    90deg,
    rgba(65, 74, 157, 1) 0%,
    rgba(89, 104, 186, 1) 60%
  ) !important;
  color: #fff;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.7);
  border: 2px solid #5968ba !important;
}

.user-content-header {
  background-image: url("../images/profile-background.jpg");
  background-color: #262626;
  background-position: center center;
  background-repeat: no-repeat;
  height: 320px;
  border-radius: 8px;
  color: #fff;
  margin-bottom: 10px;
  overflow: hidden;
}

.user-content-header .profile-avatar {
  width: 200px;
  height: 200px;
  background-size: 200px 200px;
  border: 5px solid #f1f1f1;
  -webkit-box-shadow: inset 0px 0px 0px 3px #141414;
  -moz-box-shadow: inset 0px 0px 0px 3px #141414;
  box-shadow: inset 0px 0px 0px 3px #141414;
}

.user-content-header .profile-avatar .profile-name {
  font-size: 28px;
}

.user-content-header .profile-avatar .avatar-edit {
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 999;
  transition: all 0.3s ease-in-out;
  display: none;
}

.user-content-header .profile-avatar:hover .avatar-edit {
  display: block;
}

.user-content-header .profile-avatar .avatar-edit .medium-icon {
  transform: scale(0.9);
}

.user-content-header .user-content-boxes {
  height: 260px;
}

.user-content-menu {
  list-style-type: none;
  margin: 0;
  display: flex;
  flex-direction: row;
  padding: 0 10%;
  height: 60px;
}

.user-content-menu li {
  flex: 1 1 0;
}

.user-content-menu li a {
  line-height: 60px;
  color: #fff;
  font-weight: 300;
  font-size: 23px;
  text-align: center;
  display: block;
  text-shadow: 0 1px 6px #000;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased !important;
  -moz-font-smoothing: antialiased !important;
  text-rendering: optimizelegibility !important;
  letter-spacing: 0.03em;
}

.user-content-menu li a.active {
  color: #ffb600;
  box-shadow: inset 0px -5px 0px 0px #ffb600;
}

.user-content-menu li a:hover,
.user-content-menu li a:active {
  color: #ffb600;
  box-shadow: inset 0px -5px 0px 0px #ffb600;
}

.user-content-header a,
.user-content-header a:hover {
  text-decoration: none;
}

.user-content-header .profile-stats,
.user-content-header .profile-user,
.user-content-header .profile-user-rank {
  -ms-flex-align: center !important;
  align-items: center !important;
  text-align: center;
  justify-content: center;
}

.user-content-header .profile-stats .bet-count {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.user-content-header .profile-stats .bet-count a {
  color: #fff;
  font-size: 96px;
  line-height: 96px;
  text-shadow: 0 1px 6px #000;
}

.user-content-header .profile-stats .bet-count-title {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.user-content-header .profile-stats .bet-count-title a {
  text-transform: uppercase;
  color: #ffb600;
  font-size: 33px;
  font-weight: 300;
  text-shadow: 0 1px 6px #000;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #f1f1f1;
}

.bets-header.active {
  background-color: #ffb600 !important;
}

.list-box.bets,
.list-box.my-bets {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.25) !important;
  margin-bottom: 30px;
}

.list-box.bets .bets-header {
  background-color: #313131;
  padding: 10px 14px;
  box-sizing: border-box;
  position: relative;
  border-bottom: 1px solid #dbdbdb;
  border-top: 1px solid #dbdbdb;
}

.logged-in .list-box.bets .special-bets .bets-header:after {
  display: none;
}

.logged-in .list-box.bets .bets-header.favorite:after {
  content: " ";
  width: 31px;
  height: 31px;
  display: block;
  position: absolute;
  right: 9px;
  top: 5px;
  background-image: url("../images/tiger-sprite.png");
  background-position: -720px -92px;
}

.logged-in .list-box.bets .bets-header.favorite:hover:after {
  background-position: -749px -92px;
}

.logged-in .list-box.bets .bets-header.favorite.active:after {
  background-position: -779px -92px;
}

.list-box.bets .bets-header h2 {
  font-size: 17px;
  color: #FFF;
  font-weight: normal;
  margin: 0 0 0 30px;
}

.list-box.bets .bets-header .small-icon {
  transform: scale(0.45);
  position: absolute;
  top: -15px;
  left: -11px;
}

.list-box.bets .special-bets .bets-header {
  background-color: transparent;
  padding-left: 0px;
  border-bottom: 0px;
  padding-top: 0px;
  margin-bottom: 0px;
}

.list-box.bets .special-bets .bets-header h2 {
  color: #262626;
  margin: 0px;
}

.list-box.bets .special-bets .bets-header h2 .fas,
.list-box.bets .special-bets .bets-header h2 .fa {
  color: #007bff;
}

.list-box.bets .special-bets .cell {
  border-right-color: #eadfe6;
  justify-content: center;
  align-items: center;
  flex: none;
  display: flex;
  font-family: "Oswald", sans-serif;
  flex-direction: column;
  margin-bottom: 1px;
}

.list-box.bets .special-bets .cell:last-child {
  border-right: 1px solid #dbdbdb;
}

.list-box.bets .special-bets .cell.active {
  background-color: #ffb600;
  color: #fff !important;
}

.list-box.bets .special-bets .cell.active:hover {
  background-color: #ffb600;
}

.list-box.bets .special-bets .cell.active:hover .mini-legend,
.list-box.bets .special-bets .cell.active .mini-legend {
  color: #fff;
}

.list-box.bets .special-bets .cell.disabled {
  cursor: default;
}

.list-box.bets .special-bets .cell.disabled:hover {
  background: #fff;
  text-shadow: none;
}

.list-box.bets .special-bets .cell:hover {
  background: #262626;
  color: #fff;
}

.list-box.bets .special-bets .cell:hover .mini-legend {
  color: #fff;
}

.list-box.bets .special-bets .cell.inactive .quote,
.list-box.bets .special-bets .cell.inactive .mini-legend {
  color: #878787 !important;
}

.list-box.bets .special-bets .cell .quote {
  font-size: 14px;
  font-family: 'Roboto', Verdana, Arial;
}

.list-box.bets .special-bets .cell .mini-legend {
  line-height: 13px;
  font-size: 11px;
  display: block;
  color: #787878;
  font-family: 'Roboto', Verdana, Arial;
  text-align: center;
}

.category .list-box.bets {
  margin-bottom: 24px;
}

.list-box.bets .cell.encounter {
  font-size: 14px;
  padding: 8px 10px;
  display: inline-grid; /* TEST */
  align-items: center; /* TEST */
}

.list-box.bets .cell.encounter .teams {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.list-box.bets .cell.encounter .teams span {
  flex: 1 1 0;
}

.list-box.bets .cell.bet {
  align-items: center;
  display: flex;
  font-size: 14px;
}

.list-box.bets .cell.bet .bet-time {
  font-weight: bold;
  color: #262626;
}

.list-box.bets .entry.live .cell.bet .bet-time {
  color: #f71700;
}

.list-box.bets .entry.upcoming .cell.bet .bet-time {
  color: #f79000;
}

.list-box.bets .entry.finish .cell.bet .bet-time {
  color: #262626;
}


.list-box.bets .live-result {
  display: flex;
  justify-content: flex-end;
}

.list-box .live .bet,
.live-result {
  color: #262626;
  font-weight: bold !important;
}

.list-box.bets .entry .cell.more-bets {
  align-items: center;
  display: flex;
  justify-content: center;
}

.list-box.bets .entry .cell.more-bets i {
  margin-left: 5px;
}

.list-box.bets .entry .cell.more-bets.open {
  background: #dbdbdb;
}

.list-box.bets .entry .cell.more-bets.open i:before {
  content: "\f077";
}

.list-box.bets .entry .cell.home-win,
.list-box.bets .entry .cell.draw,
.list-box.bets .entry .cell.away-win {
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: bold !important;
  line-height: 14px;
  font-family: 'Roboto', Verdana, Arial;
}

.list-box.bets .entry .cell.home-win,
.list-box.bets .entry .cell.draw,
.list-box.bets .entry .cell.away-win {
  flex-direction: column;
}

.list-box.bets .entry .cell.active {
  background-color: #ffb600 !important;
  color: #fff !important;
}

.list-box.bets .entry .cell.active:hover {
  background-color: #ffb600;
}

.list-box.bets .entry .cell.placed,
.list-box.bets .special-bets .cell.placed {
  background-image: url("../images/icon_grey_betslip_small.png");
  background-position: 1px bottom;
  background-repeat: no-repeat;
  color: #262626;
}

.list-box.bets .entry .cell.inactive.placed:hover,
.list-box.bets .special-bets .cell.inactive.placed:hover {
  background-image: url("../images/icon_grey_betslip_small.png") #fff !important;
  background-position: 1px bottom !important;
  background-repeat: no-repeat !important;
  color: #262626 !important;
}

.list-box.bets .entry .cell.placed:hover,
.list-box.bets .special-bets .cell.placed:hover {
  background: url("../images/icon_grey_betslip_small_white.png") no-repeat 1px
    bottom #262626;
  color: #fff;
}

.list-box.bets .cell.tend-up {
  color: #3bcc17;
}

.list-box.bets .cell.tend-down {
  color: #ed1f1f;
}

.list-box.bets .cell.tend-up:before,
.list-box.bets .cell.tend-down:before {
  right: -4px;
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  position: absolute;
}

.list-box.bets .cell.tend-up:before {
  border-bottom: 9px solid #3bcc17;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 1px;
}

.list-box.bets .cell.tend-down:before {
  border-bottom: 9px solid #ed1f1f;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  bottom: 1px;
}

.list-box.bets .special-bets .cell.placed:hover .mini-legend {
  color: #fff;
}

.list-box.bets .entry .cell.placed.active,
.list-box.bets .special-bets .cell.placed.active {
  background: url("../images/icon_grey_betslip_small_white.png") no-repeat 1px
    bottom #ffb600 !important;
  color: #fff !important;
}

.list-box.bets .entry .cell.placed.active:hover,
.list-box.bets .special-bets .cell.placed.active:hover {
  background: url("../images/icon_grey_betslip_small_white.png") no-repeat 1px
    bottom #ffb600 !important;
}

.list-box.bets .special-bets .cell.placed.active:hover .mini-legend {
  color: #fff;
}

.list-box.bets .entry .cell.inactive,
.list-box.bets .special-bets .cell.inactive {
  opacity: 1;
}

.list-box.bets .entry .cell.inactive {
  background: #fff;
}

.list-box.bets .entry .cell.inactive:hover {
  background: #fff;
}

.list-box.bets .entry.zebra .cell.inactive {
  background: #f5f6f6;
}

.list-box.bets .special-bets .cell.inactive {
  background: #fff;
}

.list-box.bets .entry .cell.inactive.placed,
.list-box.bets .special-bets .cell.inactive.placed {
  background-image: url("../images/icon_grey_betslip_small.png") !important;
  background-position: 1px bottom !important;
  background-repeat: no-repeat !important;
}

.list-box.bets .entry .cell.inactive:hover,
.list-box.bets .special-bets .cell.inactive:hover {
  text-decoration: none !important;
  color: #262626 !important;
  opacity: 1 !important;
  cursor: default !important;
  text-shadow: none !important;
}

.list-box.bets .entry .cell.inactive .quote,
.list-box.bets .special-bets .cell.inactive .quote {
  font-size: 0px;
  font-family: "Font Awesome 5 Free";
}

.list-box.bets .entry .cell.inactive .quote:before,
.list-box.bets .special-bets .cell.inactive .quote:before {
  content: "\f023" !important;
  font-size: 16px !important;
  line-height: 24px;
  color: #878787 !important;
  font-style: normal;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  padding-top: 3px;
}

.list-box.bets .special-bets .cell.placed:hover .mini-legend {
  color: #fff;
}

.list-box .entry.zebra .cell {
  background-color: #f5f6f6;
}

.list-box .cell {
  flex: 1 1 0;
  border-right: 1px solid #dbdbdb;
  padding: 8px 10px;
  background-color: #fff;
  color: #262626;
  font-weight: normal !important;
}

.list-box.bets .live.goal .cell.encounter {
  animation: target-fade 2s 1;
  -webkit-animation: target-fade 2s 1;
  -moz-animation: target-fade 2s 1;
}

@keyframes target-fade {
  0% {
    background-color: rgba(255, 182, 0, 1);
  }
  100% {
    background-color: rgba(255, 255, 255, 1);
  }
}
@-webkit-keyframes target-fade {
  0% {
    background-color: rgba(255, 182, 0, 1);
  }
  100% {
    background-color: rgba(255, 255, 255, 1);
  }
}
@-moz-keyframes target-fade {
  0% {
    background-color: rgba(255, 182, 0, 1);
  }
  100% {
    background-color: rgba(255, 255, 255, 1);
  }
}

.list-box.bets .zebra.live.goal .cell.encounter {
  animation: target-fade-zebra 2s 1;
  -webkit-animation: target-fade-zebra 2s 1;
  -moz-animation: target-fade-zebra 2s 1;
}

@keyframes target-fade-zebra {
  0% {
    background-color: rgba(255, 182, 0, 1);
  }
  100% {
    background-color: rgba(237, 237, 237, 1);
  }
}

@-webkit-keyframes target-fade-zebra {
  0% {
    background-color: rgba(255, 182, 0, 1);
  }
  100% {
    background-color: rgba(237, 237, 237, 1);
  }
}
@-moz-keyframes target-fade-zebra {
  0% {
    background-color: rgba(255, 182, 0, 1);
  }
  100% {
    background-color: rgba(237, 237, 237, 1);
  }
}

.list-box .cell:last-child {
  border-right: 0px;
}

.list-box.my-bets .entry .cell {
  display: -ms-flexbox !important;
  display: flex !important;
  align-items: center;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.list-box.my-bets .entry .cell.result,
.list-box.my-bets .entry .cell.bet-amount {
  overflow-wrap: anywhere;
}

.list-box.my-bets .entry .cell.result .label,
.list-box.my-bets .entry .cell.bet-amount .coin-use span {
  color: #ffb600;
}

.list-box.balance .entry .cell {
  display: -ms-flexbox !important;
  display: flex !important;
  align-items: center;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

/* .list-box.my-bets .entry.combination:hover {
-webkit-box-shadow: 0px 0px 5px 0px rgba(50, 50, 50, 0.75);
-moz-box-shadow:    0px 0px 5px 0px rgba(50, 50, 50, 0.75);
box-shadow:         0px 0px 5px 0px rgba(50, 50, 50, 0.75);
} */

.list-box.my-bets .entry.combination .options .cell.bet-amount,
.list-box.my-bets .entry.combination .options .cell.quote,
.list-box.my-bets .entry.combination .options .cell.result {
  justify-content: normal !important;
  padding-top: 35px;
}

.list-box.my-bets .entry .cell.bet {
  -ms-flex-pack: left !important;
  justify-content: left !important;
}

.list-box.balance .entry .cell.bet {
  -ms-flex-pack: left !important;
  justify-content: left !important;
}

.list-box.my-bets .entry.combination .cell.bet {
  align-items: normal;
}

.list-box.my-bets .entry.combination .cell.status {
  align-items: normal;
  padding-top: 35px;
}

.list-box.my-bets .entry.combination .cell .bet-infos {
  margin-top: 15px;
}

.list-box.my-bets .entry .cell .bet-infos {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.list-box .entry .medium-icon {
  transform: scale(0.7);
  margin-right: -2px;
}

.list-box.my-bets .entry .bet-infos,
.list-box.balance .entry .bet-infos {
  font-size: 16px;
  line-height: 22px;
  color: #141414;
}

.list-box.my-bets .entry .bet-infos .bet-time {
  font-weight: 300;
  color: #999;
}

.list-box.my-bets .entry .cell.encounter,
.list-box.balance .entry .cell.origin {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.list-box.my-bets .entry.combination .cell.encounter {
  align-items: stretch;
  padding: 0px;
}

.list-box.balance .entry .cell.origin span {
  font-weight: 700;
  font-size: 16px;
}

.list-box.my-bets .entry .cell.encounter .game {
  font-size: 14px;
  line-height: 18px;
}

.list-box.my-bets .entry.combination .cell.encounter .game {
  border-bottom: 3px dotted #eadfe6;
  padding: 7px 10px;
}

.list-box.my-bets .entry.combination .cell.encounter .game:last-child {
  border: 0px;
}

.list-box.my-bets .entry .cell.encounter .game.pending {
  color: #ffb600;
}

.list-box.my-bets .entry .cell.encounter .game.win {
  color: #49b751;
}

.list-box.my-bets .entry .cell.encounter .game.loss {
  color: #d24848;
}

.list-box .entry .cell.bet-amount,
.list-box .entry .cell.quote,
.list-box .entry .cell.result {
  line-height: calc(100vw * 0.013);
  font-weight: 700;
  text-transform: uppercase;
  flex-direction: column;
  line-height: 16px;
  font-size: 16px;
  text-align: center;
}

.list-box.my-bets .entry .cell.status {
  color: #141414;
  background-color: #fff;
  font-size: 16px !important;
}

.list-box.my-bets .entry {
  position: relative;
}

.list-box.my-bets .entry .cell.status.pending {
  background: #ffb600;
  color: #fff;
}

.list-box.my-bets .entry .cell.status.result-loss {
  background: #d24848;
  color: #fff;
}

.list-box.my-bets .entry .cell.status.result-win {
  background: #49b751;
  color: #fff;
}

.list-box .legend .cell:last-child {
  border-right: 0px;
}

.list-box .legend .options .cell:last-child,
.list-box .entry .options .cell:last-child {
  border-right: 1px solid #dbdbdb;
}

.list-box .entry .options {
  padding: 0 !important;
}

.list-box .legend .cell {
  color: #878787;
  padding: 8px 10px;
}

.list-box .entry:last-child {
  border-bottom: 0px;
}

.list-box .legend,
.list-box .entry {
  overflow: hidden;
  border-bottom: 1px solid #dbdbdb;
}

.list-box .legend {
  -moz-border-radius-topleft: 8px;
  -webkit-border-top-left-radius: 8px;
   border-top-left-radius: 8px;
  -moz-border-radius-topright: 8px;
  -webkit-border-top-right-radius: 8px;
  border-top-right-radius: 8px;
}

.list-box.bets .legend {
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
}

.content-area.arena .list-box .legend {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
}

.profile-box {
  margin-bottom: 10px;
}

.profile-box hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.profile-box .change-email .card,
.profile-box .change-password .card {
  background-color: #f1f1f1;
}

.profile-box .change-email .card .form-group,
.profile-box .change-password .card .form-group {
  margin-bottom: 0.5rem;
}

.profile-box .header {
  align-items: center;
  display: flex;
  padding: 0 23px;
  line-height: 70px;
  font-size: 24px;
  -moz-border-radius-topleft: 8px;
  -webkit-border-top-left-radius: 8px;
  border-top-left-radius: 8px;
  -moz-border-radius-topright: 8px;
  -webkit-border-top-right-radius: 8px;
  border-top-right-radius: 8px;
}

.profile-box .header i.fa {
  margin-right: 7px;
}

.profile-box .body {
  padding: 10px;
  -moz-border-radius-bottomright: 8px;
  -webkit-border-bottom-right-radius: 8px;
  border-bottom-right-radius: 8px;
  -moz-border-radius-bottomleft: 8px;
  -webkit-border-bottom-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.profile-box.settings .header {
  background-color: #414141;
  line-height: 50px;
  font-size: 20px;
  color: #fff;
  text-transform: uppercase;
}

.profile-box.settings .body {
  background-color: #fff;
  padding: 15px 23px;
}

.profile-box.achievements .body {
  padding: 0px 0px 22px 0px;
  background: linear-gradient(
    to bottom,
    rgba(46, 46, 46, 1) 0%,
    rgba(38, 38, 38, 1) 33%,
    rgba(38, 38, 38, 1) 100%
  );
}

.profile-box.achievements .header {
  background-color: #262626;
  color: #fff;
  text-transform: uppercase;
}

.achievement-list {
  margin: 0;
  padding: 0 0 0px;
  list-style: none;
  background: linear-gradient(
    to bottom,
    rgba(46, 46, 46, 1) 0%,
    rgba(38, 38, 38, 1) 33%,
    rgba(38, 38, 38, 1) 100%
  );
}

.achievement-list li {
  position: relative;
  min-height: 84px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  padding-bottom: 18px;
}

.achievement-list li .big-icon {
  transform: scale(0.85);
  top: 8px;
  left: 10px;
}

.achievement-list li .progress {
  margin: 7px 22px 0 90px;
}

.achievement-info {
  min-height: 42px;
  font-size: 17px;
  padding: 17px 130px 0 92px;
  box-sizing: border-box;
  line-height: 22px;
  color: #fff;
}

.achievement-requirement {
  color: #939393;
  margin-left: 2px;
  font-size: 14px;
}

.achievement-level {
  position: absolute;
  right: 0;
  top: 15px;
  font-size: 23px;
  margin-right: 24px;
}

.profile-box.statistic .header {
  background-color: #f7f7f7;
  color: #262626;
  text-transform: uppercase;
}

.profile-box.statistic .body {
  background-color: #fff;
}

.profile-box .big-numbers {
  -ms-flex-align: center !important;
  align-items: center !important;
  text-align: center;
  justify-content: center;
  padding: 16px 0px 16px 0px;
  background-color: #f1f1f1;
  margin: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.profile-box .big-numbers .numbers-headline {
  font-size: 16px;
  line-height: 16px;
}

.profile-box .small-numbers {
  -ms-flex-align: center !important;
  align-items: center !important;
  text-align: center;
  justify-content: center;
  padding: 13px 0px 13px 0px;
  background-color: #f7f7f7;
  margin: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.profile-box .small-numbers .numbers-headline {
  color: #ffb600;
  font-size: 13px;
  line-height: 13px;
}

.numbers-headline {
  color: #ffb600;
  font-size: 16px;
  line-height: 16px;
  text-transform: uppercase;
}

.numbers-value {
  color: #262626;
}

.big-numbers .numbers-value {
  font-size: 31px;
  line-height: 40px;
}

.small-numbers .numbers-value {
  font-size: 23px;
  line-height: 35px;
  color: #262626;
}

.user-area input {
  background: #373737;
  border: 2px solid #403f3f !important;
  box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  color: #aeaeae;
  line-height: 34px;
  padding: 0 10px;
  box-sizing: border-box;
  border-radius: 5px !important;
}

.user-area input:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: none;
}

.user-area .register-area,
.user-area .login-area {
  width: 100%;
  overflow: hidden;
}

.user-area .register-area a.password-forget {
  font-size: 13px;
  color: #767676;
}

.user-area .register-area .register-now {
  font-size: 15px;
  text-align: left;
}

.user-area .register-area .btn {
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
  padding: 0.5rem 1.8rem;
  font-weight: 700;
}

.user-area .register-area .btn:focus {
  box-shadow: none;
}

.user-area .register-area {
  padding: 20px 0px;
  text-align: center;
}

.user-area .register-area .register-button {
  margin-right: 3px;
}

.logged-in .user-area {
  padding: 0px;
}

.user-area .login-area {
  height: 100px;
}

.user-area .login-area .profile-link {
  height: 100px;
  width: 90px;
  float: left;
  transition: all 0.3s ease-in-out;
}

.user-area .login-area .profile-link:hover {
  transform: scale(1.05);
}

.user-area .login-area .profile-link:hover .profile-avatar {
  border-color: #ff8c0e;
}

.user-area .login-area .profile-link.active .profile-avatar {
  border-color: #ff8c0e;
}

.user-area .login-area a:hover,
.user-area .login-area a:focus,
.user-area .login-area a:active {
  text-decoration: none !important;
}

.profile-coins,
.profile-booster,
.xp-booster,
.your-profile,
.your-bets {
  /* width: 33.33%; bei 3 einträgen */
  width: 50%;
  float: left;
  padding: 3px 12px 2px 0;
  box-sizing: border-box;
  font-weight: 300;
}

.profile-coins a,
.your-profile a,
.your-bets a,
.profile-booster a,
.xp-booster a {
  color: #fff;
  display: block;
  background: #323232;
  width: 100%;
  border-radius: 6px;
  box-shadow: 2px 3px 4px 2px rgba(0, 0, 0, 0.15);
  height: 39px;
  margin-top: 4px;
  line-height: 36px;
  padding: 0px 0px 0 40px;
  box-sizing: border-box;
  position: relative;
  border: 2px solid rgba(255, 255, 255, 0.04);
  font-size: 0.9rem;
  font-weight: 500;
}

.profile-coins a {
  color: #fff;
}

.profile-coins a:hover,
.your-bets a:hover,
.profile-booster a:hover,
.xp-booster a:hover {
  background-color: #4b4b4b;
}

.user-area .login-area .user-menu .profile-button {
  line-height: 1.1rem;
  z-index: 1;
}

.profile-button .medium-icon {
  position: absolute;
  top: -18px;
  left: -16px;
  transform: scale(0.45);
}

.profile-button span.highlight {
  font-weight: bold;
  color: #fff;
  display: inline-block;
}

.profile-coins .medium-icon {
  background: url("../images/tiger-sprite.png");
  background-position: -280px 0;
}

.your-bets .medium-icon {
  background: url("../images/your-bets-gray-small.png");
  background-repeat: no-repeat;
  background-position: 7px 6px;
}

.your-profile .medium-icon {
  background: url("../images/yourprofile-gray-small.png");
  background-repeat: no-repeat;
  background-position: 7px 6px;
}

.xp-booster .medium-icon {
  background: url("../images/booster-xp-gray-small.png");
  background-repeat: no-repeat;
  background-position: 7px 6px;
}

.profile-booster .medium-icon {
  background: url("../images/booster-coins-gray-small.png");
  background-position: 2px 6px;
  background-repeat: no-repeat;
}

.league-menu {
  width: calc(100% - 100px);
  height: 50px;
  justify-content: center;
  align-items: center !important;
  text-align: center;
}

.user-area .login-area .video-ads-gift {
  margin-left: 8px;
}

.video-ads-gift.animated {
  opacity: 1;
  -webkit-animation: flash linear 1.5s infinite;
  animation: flash linear 1.5s infinite;
}

@-webkit-keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
  0% {
    width: 63px;
  }
  50% {
    width: 69px;
  }
  100% {
    width: 63px;
  }
}
@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.08);
  }
  100% {
    transform: scale(1);
  }
}

.user-area .login-area .logoff-button {
  margin-left: 8px;
  transform: scale(0.8);
}

.leage-progress-marker {
  width: 60%;
  height: 50px;
  position: absolute;
  background: rgba(255, 255, 255, 0.2);
  left: 0;
  z-index: 0;
}

.progress {
  background-color: #565656;
  border-radius: 20px;
  overflow: visible;
}

.progress-bar {
  background: linear-gradient(
    to right,
    rgba(234, 90, 0, 1) 0%,
    rgba(255, 143, 16, 1) 27%,
    rgba(255, 184, 32, 1) 64%,
    rgba(255, 193, 36, 1) 83%,
    rgba(255, 97, 12, 1) 100%
  );
  -moz-border-radius-topleft: 50px;
  -webkit-border-top-left-radius: 50px;
   border-top-left-radius: 50px;
  -moz-border-radius-bottomleft: 50px;
  -webkit-border-bottom-left-radius: 50px;
  border-bottom-left-radius: 50px;
  box-shadow: 0px 3px 8px 2px rgba(0, 0, 0, 0.4);
  padding-bottom: 2px;
  z-index: 1;
}

.progress.league {
  position: relative;
  font-weight: bold;
  color: #fff;
  transition: all 0.3s ease-in-out;
  height: 19px;
  overflow: hidden;
  box-shadow: 0px 3px 8px 2px rgba(0, 0, 0, 0.2);
  border: 2px solid rgba(58,58,58,1);
}

.progress-info-left {
  left: 12px;
  position: absolute;
  line-height: 16px;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 1);
  z-index: 2;
}

.progress-info-right {
  right: 12px;
  position: absolute;
  line-height: 16px;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 1);
  z-index: 2;
}

.league-menu .progress-link,
.progress-link {
  display: contents;
  position: relative;
}

.league-menu .progress-link:hover .progress.league {
  box-shadow: 0px 3px 8px 2px rgba(255, 129, 0, 0.4);
}



.rank-beginner-small {
  background: url("../images/rank-beginner-small.png");
  width: 64px !important;
  height: 53px !important;
}

.rank-amateur-small {
  background: url("../images/rank-amateur-small.png");
  width: 64px !important;
  height: 53px !important;
}

.rank-semipro-small {
  background: url("../images/rank-semipro-small.png");
  width: 64px !important;
  height: 53px !important;
}

.rank-pro-small {
  background: url("../images/rank-pro-small.png");
  width: 64px !important;
  height: 53px !important;
}

.rank-elite-small {
  background: url("../images/rank-elite-small.png");
  width: 64px !important;
  height: 53px !important;
}

.rank-worldclass-small {
  background: url("../images/rank-worldclass-small.png");
  width: 64px !important;
  height: 53px !important;
}

.rank-beginner-big {
  background: url("../images/rank-beginner-big.png") !important;
  width: 150px !important;
  height: 180px !important;
}

.rank-amateur-big {
  width: 150px;
  height: 180px;
  background: url("../images/rank-amateur-big.png") !important;
}

.rank-semipro-big {
  width: 150px;
  height: 180px;
  background: url("../images/rank-semipro-big.png") !important;
}

.rank-pro-big {
  width: 150px;
  height: 180px;
  background: url("../images/rank-pro-big.png") !important;
}

.rank-elite-big {
  width: 150px;
  height: 180px;
  background: url("../images/rank-elite-big.png") !important;
}

.rank-worldclass-big {
  width: 150px;
  height: 180px;
  background: url("../images/rank-worldclass-big.png") !important;
}

.profile-link {
  justify-content: center;
  align-items: center !important;
  text-align: center;
  z-index: 0;
}

.profile-avatar {
  width: 70px;
  height: 70px;
  background: url("../images/avatar.png") no-repeat center center;
  background-size: 70px 70px;
  display: block;
  position: relative;
  border-radius: 100px;
  border: 2px solid #FFF;
  -webkit-box-shadow: inset 0px 0px 0px 3px #141414;
  -moz-box-shadow: inset 0px 0px 0px 3px #141414;
  box-shadow: inset 0px 0px 0px 3px #141414;
}

.profile-name {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 0px;
  margin: 0 auto;
  text-shadow: 0 1px 6px #000;
  color: #fff;
}

.tiger-menu {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.dropdown-item {
  cursor: pointer;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff !important;
  background-color: #ff8c0e;
  font-weight: 600;
  text-decoration: none !important;
}

.dropdown-item:focus,
.dropdown-item:hover {
  text-decoration: none !important;
}

.upcoming-sidebar {
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px !important;
}

.upcoming-sidebar .btn {
  background-color: #1e1e1e;
  color: #fff;
  margin-bottom: 4px;
  margin-right: 4px;
  border: 2px solid #1e1e1e;
}

.upcoming-sidebar .btn:hover {
  background-color: #1a1a1a;
  border-color: #1a1a1a;
}

.upcoming-sidebar .btn:focus {
  box-shadow: none;
}

.upcoming-sidebar .btn.active {
  background-color: #0e0e0e;
  border-bottom: 2px solid #ffb600;
  border-top: 2px solid #0e0e0e;
  border-left: 2px solid #0e0e0e;
  border-right: 2px solid #0e0e0e;
}

ul.quick-links {
  margin-bottom: 25px;
}

.quick-links li a {
  padding: 7px 28px 7px 20px;
  color: #fff;
}

.quick-links li a.active {
  background-color: #0e0e0e;
}

.quick-links li a:hover {
  background-color: #0e0e0e;
}


.headline-menu {
  margin-bottom: 1rem;
}

.headline-menu .headline {
  font-size: 23px;
  line-height: 30px;
  color: #262626;
  font-weight: 700;
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}

.sport-sorter {
  align-items: center;
  text-align: right;
}

.sport-sorter .label {
  margin-right: 5px;
  color: #0e0e0e;
}

.sport-sorter .btn-group {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.25) !important;
  border-radius: 8px;
  overflow: hidden;
}

.sport-sorter .btn {
  background-color: #fff;
  color: #262626;
  padding: 0.25rem .7rem;
}

.sport-sorter .btn.active {
  background-color: #f5f6f6;
  color: #262626;
  font-weight: bold;
}

.sport-sorter .btn:focus {
  box-shadow: none;
}

.sport-sorter .btn-group > .btn-group:not(:first-child),
.sport-sorter .btn-group > .btn:not(:first-child) {
  margin-left: 0px;
}



.sport-filter {
  width: 100%;
  background: #fff;
  color: #fff;
  -moz-border-radius-topleft: 8px;
  -webkit-border-top-left-radius: 8px;
   border-top-left-radius: 8px;
  -moz-border-radius-topright: 8px;
  -webkit-border-top-right-radius: 8px;
  border-top-right-radius: 8px;
  overflow: hidden;
  margin-bottom: 0px !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.25) !important;
}

.sport-filter .nav-link {
  color: #262626;
  padding: 0.8rem 1rem;
  font-size: 14px;
}

.sport-filter .nav-link.active {
  border-bottom: 3px solid #313131;
  font-weight: bold;
}

.sport-filter.is-loading .nav-link,
.sport-filter.is-loading .nav-link.active {
  color: #b9b9b9;
  cursor: default;
}
.sport-filter.is-loading .nav-link.active {
  border-bottom: solid #bfbfbf;
}
.sport-sorter.is-loading .label,
.sport-sorter.is-loading .btn,
.sport-sorter.is-loading .btn.active {
  color: #717171;
  cursor: default !important;
}

.bet-header {
  width: 100%;
  background: #FFF;
  color: #fff;
  border-radius: 8px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.25) !important;
}

.bet-header .time ul {
  flex: 1 1 auto;
}

.bet-header .time .list-group-item:first-child {
  -moz-border-radius-topleft: 8px;
  -webkit-border-top-left-radius: 8px;
  border-top-left-radius: 8px;
  -moz-border-radius-bottomleft: 8px;
  -webkit-border-bottom-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.bet-header .time .list-group-item:last-child {
  border-radius: 0px !important;
  box-shadow: none;
}

.bet-header .time a.list-group-item {
  justify-content: center;
  align-items: center !important;
  border-radius: 0px;
  background-color: transparent;
  border: 0px;
  color: #262626;
  padding: 0.25rem 1rem !important;
  font-size: 16px;
  box-shadow: inset -1px 0 0 #dbdbdb;
}

.bet-header .time a.list-group-item.active,
.bet-header .time a.list-group-item.active:focus,
.bet-header .time a.list-group-item.active:active {
  background-color: #ededed;
  color: #262626;
}

.bet-header .time a.list-group-item-action:focus,
.bet-header .time a.list-group-item-action:hover {
  z-index: 1;
  color: #262626;
  text-decoration: none;
  background-color: #fff;
}

.bet-header .sport,
.bet-header .time {
  box-shadow: inset -1px 0 0 #dbdbdb;
}

.bet-header .sport .nav-item {
  padding: 0px;
}

.bet-header .sport a {
  padding: 0px;
  cursor: pointer;
}

.bet-header .btn-secondary {
  color: #262626;
  background-color: #ededed;
  border-color: #ededed;
  font-weight: 600;
}

.bet-header .btn-secondary:not(:disabled):not(.disabled).active,
.bet-header .btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #454545;
  border-color: #454545;
}

.bet-header .time,
.bet-header .upcoming {
  justify-content: center;
  flex-direction: column;
}

.bet-header .time .dropdown,
.bet-header .upcoming .dropdown {
  padding: 10px;
}

.bet-header .sport .big-icon {
  transform: scale(0.7);
  margin: 0px;
  width: 55px;
  height: 65px;
}

.bet-header .sport .nav-link.disabled .big-icon {
  opacity: 0.2;
}

.bet-header .sport .big-icon.soccer,
.bet-header .sport .big-icon.football {
  background-position-x: -6px;
}

.bet-header .sport .big-icon.basketball {
  background-position-x: -77px;
}

.bet-header .sport .big-icon.icehockey {
  background-position-x: -287px;
}

.bet-header .sport .big-icon.handball {
  background-position-x: -567px;
}

.bet-header .sport .big-icon.tennis {
  background-position-x: -146px;
}

/* SPRITESHEET */

.tiger-sprite {
  background: url("../images/tiger-sprite.png");
}

.small-icon {
  width: 70px;
  height: 72px;
  transform: scale(0.5);
  position: relative;
  float: left;
}

.medium-icon {
  width: 70px;
  height: 72px;
  transform: scale(0.5);
  position: relative;
  float: left;
}

.big-icon {
  width: 70px;
  height: 72px;
  position: relative;
  float: left;
}

.main-menu .big-icon {
  transform: scale(0.7);
  top: 3px;
  margin-left: -19px;
}

.huge-icon {
  width: 150px;
  height: 180px;
  position: relative;
  float: left;
  display: block;
}

.arena {
  background-position: 0 0;
}

.bets {
  background-position: -70px 0;
}

.league {
  background-position: -140px 0;
}

.ranks {
  background-position: -210px 0;
}

.betslip {
  background-position: -280px 0;
}

/* SPORTART */

.soccer, .football {
  background-position: 0 -363px;
}
.icehockey {
  background-position: -280px -360px;
}
.basketball {
  background-position: -70px -360px;
}
.handball {
  background-position: -560px -360px;
}
.tennis {
  background-position: -140px -360px;
}


/* KATEGORIE */

.sidebar .medium-icon.flag-football-germany,
.sidebar .medium-icon.flag-football-england,
.sidebar .medium-icon.flag-football-spain {
  transform: scale(1) !important;
}

.flag-football-germany {
  background-position: -814px -3px;
}

.flag-football-spain {
  background-position: -702px -3px;
}

.flag-football-england {
  background-position: -758px -3px;
}



/* RANDOM */

.rank-1 {
  background-position: 0 -432px;
}
.coin {
  background-position: -280px 0;
}
.bet-correct-green {
  background-position: -490px -288px;
}
.edit {
  background-position: -350px -360px;
}
.achievements {
  background-position: -140px -72px;
}
.betslip {
  background-position: -210px -288px;
}
.bet-pending,
.bet-PENDING {
  background-position: -420px -360px;
}
.bet-wrong,
.bet-LOSS,
.bet-CANCEL {
  background-position: -420px -216px;
}
.bet-correct,
.bet-WIN {
  background-position: -420px -288px;
}
.xp {
  background-position: -560px -290px;
}
.ticket {
  background-position: -630px -290px;
}
.chips-free {
  background-position: -921px -20px;
}
.chips-1 {
  background-position: -200px -612px;
}
.chips-2 {
  background-position: -400px -604px;
}
.chips-3 {
  background-position: -600px -601px;
}
.chips-4 {
  background-position: -800px -605px;
}
.chips-4-noshadow {
  background-position: -800px -770px;
}
.booster-2 {
  background-position: -1000px -612px;
}
.arrow-right {
  background-position: -700px -150px;
}

.league-beginner {
  background-position: -243px -801px;
}
.league-amateur {
  background-position: -33px -991px;
}
.league-semi-pro {
  background-position: -453px -801px;
}
.league-pro {
  background-position: -30px -801px;
}
.league-elite {
  background-position: -243px -991px;
}
.league-world-class {
  background-position: -453px -991px;
}

.achievement-badge {
  background-position: -668px -1003px;
}
.arena-daily-missions {
  background-position: -856px -1208px;
}
.arena-top-winner-1 {
  background-position: -858px -1003px;
}
.arena-top-winner-2 {
  background-position: -1028px -1003px;
}
.arena-top-winner-3 {
  background-position: -1198px -1003px;
}

.daily-on-point {
  background-position: -31px -1195px;
}
.daily-multitalent {
  background-position: -31px -1268px;
}
.daily-experienced {
  background-position: -31px -1342px;
}
.daily-effort {
  background-position: -31px -1418px;
}

.daily-done {
  background-position: -31px -1493px;
}

.daily-default {
  background-position: -106px -1195px;
}

.your-rank {
  background: url("../images/your-rank-de.png");
  width: 120px;
  height: 37px;
  position: absolute;
  top: 10px;
  left: -6px;
}

.booster-coins-green-big {
  background: url("../images/booster-coins-green-big.png");
  width: 200px;
  height: 200px;
}

.booster-coins-blue-big {
  background: url("../images/booster-coins-blue-big.png");
  width: 200px;
  height: 200px;
}

.booster-coins-gold-big {
  background: url("../images/booster-coins-gold-big.png");
  width: 200px;
  height: 200px;
}

.booster-coins-purple-big {
  background: url("../images/booster-coins-purple-big.png");
  width: 200px;
  height: 200px;
}

.booster-xp-purple-big {
  background: url("../images/booster-xp-purple-big.png?v2=1");
  width: 200px;
  height: 200px;
}

.booster-xp-gold-big {
  background: url("../images/booster-xp-gold-big.png?v2=1");
  width: 200px;
  height: 200px;
}

.booster-xp-blue-big {
  background: url("../images/booster-xp-blue-big.png?v2=1");
  width: 200px;
  height: 200px;
}

.booster-xp-green-big {
  background: url("../images/booster-xp-green-big.png?v2=1");
  width: 200px;
  height: 200px;
}

/* SPRITESHEET ENDE */

@media (max-width: 1520px) {
  .bet-header .time .dropdown {
    display: block !important;
  }

  .bet-header .btn-secondary {
    padding: 0.375rem 0.45rem;
  }

  .shop .huge-icon {
    width: 150px;
  }

  .shop .huge-icon.chips-free {
    background-position-x: -953px;
  }

  .shop .huge-icon.chips-1 {
    background-position-x: -223px;
  }

  .shop .huge-icon.chips-2 {
    background-position-x: -423px;
  }

  .shop .huge-icon.chips-3 {
    background-position-x: -623px;
  }

  .shop .huge-icon.chips-4 {
    background-position-x: -818px;
  }
}

@media (max-width: 1400px) {
  .progress-info-right {
    display: none;
  }

  .league-big-bar .progress .progress-info-right {
    display: block;
  }

  .achievement-list .progress-info-right {
    display: block;
  }

  .single-bet input {
    width: 70%;
  }
}

@media (max-width: 1399px) {

  .tippspiel-custom-breakpoint {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0px !important;
  }

  .main-menu .big-icon {
    transform: scale(0.6);
  }
  .main-menu a.list-group-item {
    font-size: 18px;
  }

  .sub-navigation .nav-link {
    font-size: 18px;
  }

  .bet-header .sport .big-icon {
    transform: scale(0.6);
    width: 52px;
  }

  .list-box.toplist .toplist-user {
    font-size: 14px;
  }

  .shop-deal {
    font-size: 14px;
    margin: 0 6%;
    padding: 5px 3%;
  }

  .content-area.arena .list-box .legend .cell {
    font-size: 12px;
  }

  .bet-header .sport a {
    margin-right: -5px;
    margin-left: -5px;
  }
}

@media (max-width: 1199px) {
  .inner-sidebar {
    height: calc(100vh - 90px);
    top: 175px;
  }

  .logged-in .inner-sidebar {
    top: 190px;

  }

  .highlights-slider {
    margin-bottom: 10px;
  }

  .content-area.club {
    margin-left: -30px;
    margin-right: -30px;
    margin-bottom: -30px;
    margin-top: -30px;
  }

  .card.ranking-notice {
    margin-right: 0px;
  }

  .logged-in .main-content {
    padding-top: 190px !important;
  }

  .main-content {
    padding-top: 175px !important;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
  }

  .navbar {
    display: block;
  }
  .navbar-brand {
    display: none !important;
  }
  .sidebar {
    display: none !important;
  }

  .bet-header {
    display: flex !important;
  }

  .main-menu a.list-group-item {
    height: 60px;
  }

  .bet-header .sport .big-icon.soccer {
    background-position-x: -8px;
  }

  .bet-header .sport .big-icon.basketball {
    background-position-x: -79px;
  }

  .bet-header .sport .big-icon.icehockey {
    background-position-x: -289px;
  }

  .bet-header .sport .big-icon.handball {
    background-position-x: -569px;
  }

  .bet-header .sport .big-icon.tennis {
    background-position-x: -148px;
  }

  .progress-info-right {
    display: block;
  }

  .user-content-menu li a {
    font-size: 18px;
  }

  .profile-box.achievements {
    margin-bottom: 10px;
  }

  .list-box.my-bets .entry .cell.bet {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .list-box.my-bets .entry .cell {
    justify-content: normal !important;
  }

  .list-box.my-bets .entry .cell.status {
    justify-content: center !important;
    align-items: center;
  }

  .coin-boosters,
  .xp-boosters,
  .league-ranks,
  .coin-shop {
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    min-height: 480px;
  }

  .league-ranks {
    min-height: 350px !important;
  }

  .coin-boosters .row,
  .xp-boosters .row,
  .league-ranks .row,
  .coin .row {
    flex-direction: row;
    display: flex;
  }

  .modal-dialog .tigercard {
    width: auto;
  }

  .tigercard {
    width: 350px;
  }

  .tigercard .card-reward {
    font-size: 24px;
    line-height: 28px;
  }

  .coin-boosters [class*="col"],
  .xp-boosters [class*="col"],
  .league-ranks [class*="col-"],
  .coin-shop [class*="col"] {
    width: 350px;
    max-width: 350px;
    margin-right: 10px;
  }

  .content-header {
    justify-content: center;
  }

  .list-box.my-bets .entry.combination .options .cell.bet-amount,
  .list-box.my-bets .entry.combination .options .cell.quote,
  .list-box.my-bets .entry.combination .options .cell.result {
    padding-top: 20px;
  }

  .toplist-header {
    margin-right: 0px;
  }

  .list-box.toplist {
    margin-right: 0px;
  }

  .toplist-header {
    min-height: 180px;
    padding: 100px 0 7px;
    background-position: top -5px center;
    background-size: 100%;
  }

  .toplist-header-gradient {
    height: 120px;
  }

  .toplist-header h2 {
    font-size: 25px;
    line-height: 28px;
    margin: 0 0 5px;
  }

  .switcher {
    max-width: 18%;
    min-width: 18%;
  }

  .list-box.toplist .toplist-user {
    font-size: 16px;
  }

  .shop .huge-icon {
    width: 200px;
  }

  .shop .huge-icon.chips-free {
    background-position-x: -927px;
  }

  .shop .huge-icon.chips-1 {
    background-position-x: -200px;
  }

  .shop .huge-icon.chips-2 {
    background-position-x: -400px;
  }

  .shop .huge-icon.chips-3 {
    background-position-x: -600px;
  }

  .shop .huge-icon.chips-4 {
    background-position-x: -800px;
  }

  .card-name {
    font-size: 28px;
    line-height: 32px;
    padding: 6px;
  }
}

@media (max-width: 991px) {
  .logged-in .main-content {
    padding-top: 110px !important;
  }

  .main-content {
    padding-top: 105px !important;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 20px;
  }

  .list-box .entry {
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    border-bottom: 0px;
  }


  .content-area {
    height: auto !important;
  }

  .content-area.club {
    margin-left: -12px;
    margin-right: -12px;
    margin-bottom: -12px;
    margin-top: -10px;
  }

  .main-menu a.list-group-item {
    height: 77px;
    flex-direction: column;
    font-family: 'Roboto', Verdana, Arial;
    font-size: 10px;
    letter-spacing: 0;
    text-transform: uppercase;
    line-height: 28px;
    font-weight: 800;
    box-sizing: border-box;
    padding-top: 17px;
    box-shadow: inset -1px 0 0 #efefef;
  }

  .main-menu a.list-group-item i {
    font-size: 16px;
  }

  .tooltip {
    display: none;
    position: fixed;
  }

  .main-menu a.list-group-item .big-icon {
    background-position-y: -155px;
    margin: 0;
    transform: scale(0.38);
    min-height: 56px;
    position: relative;
    top: 10px;
  }

  .main-menu a.list-group-item.betslip .big-icon {
    background-position: -276px -228px;
  }

  .main-menu a.list-group-item:hover .big-icon,
  .main-menu a.list-group-item.active .big-icon {
    background-position-y: -228px;
  }

  .user-content-menu {
    padding: 0;
  }

  .user-content-menu li a {
    font-size: 14px;
  }

  .user-content-header .profile-avatar {
    width: 90px;
    height: 90px;
    background-size: 90px 90px;
    border: 2px solid #f1f1f1;
  }

  .user-content-header .profile-avatar .profile-name {
    font-size: 20px;
  }

  .user-content-header .profile-user-rank .huge-icon {
    transform: scale(0.55);
  }

  .user-content-header .profile-stats .bet-count a {
    font-size: 40px;
    line-height: 40px;
  }

  .user-content-header .profile-stats .bet-count-title a {
    font-size: 20px;
    line-height: 26px;
  }

  .user-content-header {
    height: 215px;
  }

  .user-content-header .user-content-boxes {
    height: 155px;
  }

  .list-box.my-bets .entry .cell.encounter,
  .list-box.balance .entry .cell.origin {
    border-bottom: 2px solid #e5e1e9;
  }

  .list-box .cell {
    background-color: #FFF;
  }

  .list-box.bets .special-bets .cell {
    border-right: 2px solid;
    border-color: #eadfe6;
    padding: 5px 10px;
    margin-bottom: 2px;
  }

  .list-box.bets .special-bets .cell:last-child {
    border-right: 2px solid #eadfe6;
  }

  .list-box.bets .options .cell {
    padding: 5px 10px;
  }

  .list-box.my-bets .entry .cell.bet,
  .list-box.balance .entry .cell.bet {
    background-color: #262626;
    border-bottom: 3px solid #e5e1e9;
  }

  .list-box.my-bets .entry .cell.bet .bet-infos,
  .list-box.balance .entry .cell.bet .bet-infos {
    color: #fff;
  }

  .list-box.my-bets .entry.combination .cell.bet {
    align-items: center;
  }

  .list-box.my-bets .entry.combination .cell .bet-infos {
    margin-top: 0px;
  }

  .list-box.my-bets .entry .cell.status {
    position: absolute;
    top: 5px;
    right: 0px;
    height: 48px;
    width: 48px;
    max-width: 48px;
    min-width: 48px;
    background: none !important;
  }

  .list-box.my-bets .entry.combination .cell.status {
    align-items: center;
  }

  .list-box.my-bets .entry .cell.status .medium-icon {
    margin: 0 -25px;
  }

  .list-box.my-bets .entry .medium-icon.betslip,
  .list-box.balance .entry .medium-icon.betslip {
    transform: scale(0.5);
    margin: -12px -12px -12px -18px;
    display: block !important;
    background-position: -280px -218px;
  }

  .list-box .entry {
    margin-bottom: 15px;
  }

  .modal-dialog.system.betslip .modal-body .list-box .entry {
    margin-bottom: 12px;
  }

  .list-box.bets .entry {
    margin-bottom: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.25) !important;
  }

  .bet-pending,
  .bet-PENDING {
    background-position: -490px -360px;
  }

  .bet-wrong,
  .bet-LOSS,
  .bet-CANCEL {
    background-position: -490px -216px;
  }

  .bet-correct,
  .bet-WIN {
    background-position: -490px -288px;
  }

  .list-box.my-bets .entry .cell.bet {
    -ms-flex-pack: left !important;
    justify-content: left !important;
  }

  .content-area.club .coming-soon {
    height: 570px;
    min-height: auto;
  }

  .list-box.my-bets .entry.combination .options .cell.bet-amount,
  .list-box.my-bets .entry.combination .options .cell.quote,
  .list-box.my-bets .entry.combination .options .cell.result {
    padding-top: 10px;
  }

  .list-box.my-bets .entry.combination .cell.encounter .game {
    border-bottom-width: 2px;
  }

  .content-header.league {
    padding: 0px;
  }

  .league-header-headline {
    justify-content: center;
  }

  .content-header.league h1 {
    font-size: 24px;
    line-height: 28px;
  }

  .content-header.shop {
    padding-left: 15px;
    padding-right: 15px;
  }

  .content-header.shop h1 {
    font-size: 24px;
    line-height: 28px;
  }

  .league-info {
    display: none;
  }

  .content-header.league h1 span {
    display: block;
    text-align: center;
  }

  .tigercard .rank-element.rank-bar {
    display: flex;
  }

  .tigercard .rank-element.rank-bar .progress {
    height: 30px;
    border-width: 2px;
  }

  .tigercard .rank-element.rank-bar .progress .progress-info-left,
  .tigercard .rank-element.rank-bar .progress .progress-info-right {
    font-size: 15px;
    line-height: 27px;
    display: block;
  }

  .league-big-bar {
    display: none;
  }

  .league-ranks {
    height: 410px !important;
  }

  .league-info.mobile {
    display: block;
  }

  .switcher {
    max-width: 20%;
    min-width: 20%;
  }

  .single-bet input {
    width: 80%;
  }

  .inner-content {
    width: 100%;
  }

  .inner-sidebar {
    display: none;
  }

  .shop-deal {
    top: 0;
    width: 90%;
    margin: 0 5%;
    font-size: 20px;
    font-weight: 300;
    text-align: center;
    top: 14px;
    box-sizing: border-box;
    padding: 5px 4%;
    line-height: 22px;
  }

  .card-name {
    font-size: 28px;
    line-height: 32px;
    padding: 6px;
  }

  .arena-content-1 {
    margin-bottom: 0px;
  }

  .arena-block.top-winner {
    margin-bottom: 15px;
  }

  .daily-mission-header {
    background-size: 100%;
  }

  .list-box.my-bets .entry.combination .cell.status {
    align-items: center;
    padding-top: 0px;
  }

  .list-box.my-bets .entry .cell.status.pending {
    color: #ffb600;
  }

  .list-box.my-bets .entry .cell.status.result-loss {
    color: #d24848;
  }

  .list-box.my-bets .entry .cell.status.result-win {
    color: #49b751;
  }

  .content-area.arena .list-box.my-bets {
    overflow: auto;
    max-height: none;
  }

  .arena-block {
    height: auto;
  }

  .arena-block.deals {
    margin-bottom: 0px;
  }

  .block-header {
    min-height: 180px !important;
    padding: 100px 0 7px;
    background-position: top -5px center;
    background-size: 100%;
  }

  .block-header-gradient {
    height: 120px;
  }

  .block-header h2 {
    font-size: 25px !important;
    line-height: 28px !important;
    margin: 0 0 5px !important;
  }

  .top-winner .headline {
    font-size: 25px !important;
    line-height: 28px !important;
  }

  .block-countdown {
    font-size: 16px;
    letter-spacing: 1.5px;
  }

  .arena-content-1 .block-header {
    padding: 122px 0 0 !important;
  }

  .list-box {
    background-color: transparent;
  }

  .list-box.bets .bets-header {
    border-bottom: 0px;
  }

  .list-box.bets .cell.bet,
  .list-box.bets .cell.encounter {
    border-bottom: 1px solid #dbdbdb;
  }

  .list-box .entry .options {
    border-bottom: 1px solid #dbdbdb;
  }

  .list-box.bets .entry .cell.more-bets {
    padding: 10px 10px !important;
    border-bottom: 1px solid #dbdbdb;
  }

  .list-box.bets .entry .cell.more-bets .count {
    display: none;
  }

  .list-box .my-bets,
  .list-box .my-balance,
  .list-box .my-notifications {
    margin-bottom: 30px;
  }

  .bet-header .sport a {
    margin-right: 0px;
    margin-left: 0px;
  }

  .list-box.bets .special-bets .cell:hover {
    background: #fff;
    color: #262626;
    text-shadow: none;
  }

  .list-box.bets .special-bets .cell:hover .mini-legend {
    color: #262626;
  }

  .list-box .special-bets .row {
    margin-bottom: 2px !important;
  }

  .list-box.bets .special-bets .bets-header {
    padding-top: 14px;
    padding-bottom: 0px;
    margin-bottom: 8px;
  }

  .scroll-to-top {
    bottom: 96px;
  }

  .scroll-to-top.show {
    transition:opacity 500ms ease-out;
    opacity: 1;
    visibility: visible;
  }

}

@media (min-width: 991px) {
}

@media (max-width: 768px) {
  .logged-in .main-content {
    padding-top: 110px !important;
  }

  .main-content {
    padding-top: 105px !important;
  }

  .content-header h1 {
    font-size: 34px;
  }

  .sub-navigation .nav-link {
    font-size: 16px;
    padding: .75rem 1.25rem;
  }

  .switcher {
    max-width: 32%;
    min-width: 32%;
  }

  .content-header {
    margin-bottom: 0.5rem;
  }

}

@media (min-width: 575px) {
  .list-box.bets .entry .cell.home-win:hover,
  .list-box.bets .entry .cell.draw:hover,
  .list-box.bets .entry .cell.away-win:hover {
    background-color: #262626;
    color: #fff;
  }

  .list-box.bets .entry.zebra .cell.inactive:hover {
    background: #ededed !important;
  }

  .list-box.bets .entry .cell.inactive:hover {
    background: #FFF !important;
  }

}


@media (max-width: 575px) {

  .main-content {
    background: rgb(154,185,227);
    background: linear-gradient(180deg, rgba(154,185,227,1) 0%, rgba(255,255,255,1) 100%);
  }

  table.tippabgabe .datarow td {
    padding: .55rem .25rem;
  }

  table.tippabgabe .datarow input {
    padding: .15rem;
  }

  .bet-header .sport {
    border-bottom: 1px solid #3c3c3c;
  }

  .list-box.bets .bets-header h2 {
    font-size: 0.98em !important;
  }

  .list-box.bets .bets-header .small-icon {
    top: -18px
  }

  .user-area .login-area .video-ads-gift {
    display: none;
  }

  .table.tippabgabe, .table.wunschergebnis {
    font-size: .875em !important;
  }

  .table.tippabgabe > td, .table.wunschergebnis > td {
    padding: 7px 5px !important
  }

  .table.wunschergebnis th {
    padding: .4rem .75rem;
  }

  .table.wunschergebnis td {
    padding: .45rem .75rem;
  }

  .sport-filter .nav-link {
    font-size: .875em !important;
  }

  .loading-notification {
    font-size: .875em !important;
  }

  .bet-header .time a.list-group-item {
    font-size: .875em !important;
  }

  .bet-header .dropdown a {
    font-size: .875em !important;
  }

  .bet-header .sport {
    box-shadow: none;
  }

  .all-matches-link {
    font-size: .875em !important;
  }

  .modal-dialog.register .btn-primary,
  .modal-dialog.system .btn-primary,
  .modal-dialog.login .btn-primary {
    width: 100%;
  }

  .list-box .cell {
    padding: 5px 5px !important;
    font-size: 13px !important;
  }

  .list-box.bets .options .cell {
    padding: 5px 5px !important;
  }

  .list-box.bets .legend .cell {
    font-size: .875em !important;
  }

  .list-box.bets .cell.encounter {
    font-size: .875em !important;
  }

  .list-box.bets .cell.bet .bet-time {
    font-size: .875em !important;
  }

  .list-box.my-bets .entry .cell.encounter .game {
    font-size: 12px;
    font-weight: 500;
  }

  .list-box.bets .entry .cell.more-bets {
    justify-content: left;
  }

  .list-box.my-bets .entry.combination .cell.encounter {
    padding: 0px !important;
  }

  .list-box.toplist .toplist-user {
    font-size: 14px;
  }

  .content-header {
    padding-left: 15px;
  }

}

@media (max-width: 500px) {
  .main-content {
    padding-top: 100px !important;
  }

  .progress-info-right {
    display: none;
  }

  .user-area .login-area .profile-link {
    width: 70px;
  }

  .profile-avatar {
    width: 60px;
    height: 60px;
    background-size: 55px 55px;
    margin-top: -5px;
  }

  .profile-avatar:before {
    width: 60px;
    height: 60px;
    background-size: 60px 60px;
  }

  .profile-name {
    bottom: -9px;
  }

  .user-content-header .profile-avatar .profile-name {
    bottom: 0px;
  }

  .league-menu {
    width: calc(100% - 80px);
  }

  .single-bet input {
    width: 70%;
  }

  .list-box.bets .entry .cell.more-bets {
    justify-content: center;
  }

}

@media (max-width: 382px) {
  .logged-in .main-content {
    padding-top: 110px !important;
  }

  .main-content {
    padding-top: 150px !important;
  }
}

.system-options > select,
.system-options > select:active,
.system-options > select:focus {
  background: #393939;
  color: #fff;
}

/* prelaod */
.init-loading {
  overflow-y: visible !important;
}

.init-loader {
  top: 0;
  left: 0;
  position: fixed;
  z-index: 9999999999;
  min-height: 100vh;
  min-width: 100%;
  font-family: 'Roboto', Verdana, Arial;
  font-size: 0.875rem;
  background: #ff8c14 url("/assets/images/preload.png") no-repeat fixed center;
  background-attachment: fixed;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s ease-in opacity, 0.5s ease-in visibility;
}
.init-loading > .init-loader {
  opacity: 1;
  visibility: visible;
  transition: 0.5s ease-in opacity, 0.5s ease-in visibility;
}

/* fixing footer position */
.alt-footer {
  margin-left: auto;
  margin-right: auto;
}

/* fixing loader modal (without this fix there will be a white bar in the middle of the screen) */
.modal-dialog.loading .modal-content {
  border: none;
  box-shadow: none !important;
  background: transparent;
  text-align: center;
  display: contents;
}

/*  fix payment modal */
.modal-dialog.payment {
  max-width: 965px;
}

.modal-dialog.payment.success {
  max-width: 500px;
}

.modal-dialog.payment .modal-body {
  padding-top: 20px;
}

.modal-dialog.payment .tigercard {
  box-shadow: inset 0px 0px 0px 4px #ffb600 !important;
}

.modal-dialog.payment .tigercard .rank-icon {
  padding-top: 0px;
}

.modal-dialog.payment.success .gain-label {
  font-size: 22px;
}

.payment-iframe {
  min-height: 450px;
  border: none;
}
.payment-iframe.hidden {
  display: none;
}

/* fix video ads modal */
.modal-dialog.video-ads {
  max-width: 650px;
  text-align: center;
}
.video-ads-iframe {
  min-height: 500px;
  border: none;
}
.video-ads-iframe.hidden {
  display: none;
}

/*  animation for progress bar */
.progress-bar {
  transition: 1s ease-out width;
  width: 0%;
}

/*  countdown fixes */
.count-down span {
  display: inline !important;
}

/*  fix empty achievements */
.achievement-list {
  min-height: 348px;
}

/*  fix tooltip (postition is wrong without this) */
.tooltip {
  position: absolute !important;
}

.league-beginner,
.league-amateur,
.league-semi-pro,
.league-pro,
.league-elite,
.league-world-class,
.achievement-badge,
.arena-top-winner-1,
.arena-top-winner-2,
.arena-top-winner-3,
.arena-daily-missions {
  width: 200px;
}

/*  coin chips icons */
.chips-1,
.chips-2,
.chips-3,
.chips-4,
.chips-5,
.chips-4-noshadow {
  width: 200px;
}

/*  video ads in member header */
.video-ads-gift.locked {
  opacity: 0.5;
}
/*  inactive (for gamelist filter) */
.inactive {
  text-decoration: none;
  background-color: #eadfe6;
  color: #6c757d;
  opacity: 0.4;
  cursor: default;
}

/* toastr */
.ngx-toastr {
  color: #000 !important;
}

/* game list / live result */
.live-result {
  text-align: right;
}

/* bet list in account (void, cancel, delete) */
.list-box.my-bets .entry .cell.encounter .game.void,
.list-box.my-bets .entry .cell.encounter .game.cancel,
.list-box.my-bets .entry .cell.encounter .game.delete {
  color: #aaa;
}

.list-box.my-bets .entry .cell.status.result-void,
.list-box.my-bets .entry .cell.status.result-cancel,
.list-box.my-bets .entry .cell.status.result-delete {
  background: #aaa;
}

/* general bet (LOSS is LOSE...) */
.bet-wrong,
.bet-LOSS,
.bet-CANCEL,
.bet-LOSE {
  background-position: -420px -216px;
}
.bet-wrong,
.bet-LOSS,
.bet-CANCEL,
.bet-LOSE {
  background-position: -490px -216px;
}
.list-box.my-bets .entry .cell.encounter .game.lose {
  color: #d24848;
}

.betBoosterImg {
  width: 32px;
  height: 32px;
  display: inline;
  vertical-align: top;
  float: none;
  clear: both;
}

.pagination {
  display: flex;
  justify-content: center;
  border-radius: 8px;
}

.pagination .pagination-prev .page-link,
.pagination .pagination-next .page-link {
  padding: 0 15px;
}

.page-item.disabled .page-link {
  color: #6f7373;
}

.page-item.active .page-link {
  color: #0e0e0e;
  background-color: #ffb600;
  border: 0px;
  font-weight: bold;
}

.page-item:last-child .page-link {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.page-item:first-child .page-link {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.page-link {
  color: #0f1111;
  line-height: 46px;
  height: 46px;
  font-size: 14px;
  padding: 0 12px;
  min-width: 44px;
  text-align: center;
}

.page-link:hover {
  background-color: #f5f6f6;
  color: #0f1111;
}

.page-link:focus {
  box-shadow: none;
}

/* cursors */
.cursor-default {
  cursor: default !important;
}
.cursor-pointer {
  cursor: pointer !important;
}

/* no unterline links */
.no-underline,
.no-underline:hover {
  text-decoration: none;
}
